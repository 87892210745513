import { useEffect, useState } from 'react'
import { differenceInDays } from 'date-fns'

import { EnrolledClientWithPermissions, getMyOrganizations, MyOrganization } from '../../../wallet'
import i18n from '../../../i18n'
import { DataSourceAccount } from '../../../wallet/types/datasource'

const useConsentHistory = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [org, setOrg] = useState<MyOrganization[]>([])

  useEffect(() => {
    if (!org?.length) {
      getMyOrganizations().then((data) => {
        setIsLoading(false)
        setOrg(data)
      })
    }
  }, [org])

  const processDate = (dateString: string | undefined) => {
    if (!dateString) return ''

    const inputDate = new Date(dateString)
    const today = new Date()

    inputDate.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    const diffInDays = differenceInDays(today, inputDate)

    if (diffInDays === 0) {
      return i18n.t('wallet-consent-day')
    } else if (diffInDays === 1) {
      return i18n.t('wallet-consent-yesterday')
    } else {
      return inputDate.toISOString().split('T')[0]
    }
  }

  const handleLastActive = (clients: EnrolledClientWithPermissions[] | DataSourceAccount[]): string => {
    let lastActiveTimeArray = clients.map((client) => {
      return client.last_active
    })

    return processDate(lastActiveTimeArray[0])
  }

  const renderTypeAndLastActive = (org: MyOrganization): { type: string; lastActive: string } => {
    const { accounts, clients } = org

    if (accounts && clients) {
      return { type: `${i18n.t('wallet-consent-history-type-information')}, ${i18n.t('wallet-consent-history-type-service')}`, lastActive: handleLastActive(accounts) }
    } else if (accounts) {
      return { type: `${i18n.t('wallet-consent-history-type-information')}`, lastActive: handleLastActive(accounts) || accounts[0]?.connected_on }
    }
    return { type: `${i18n.t('wallet-consent-history-type-service')}`, lastActive: handleLastActive(clients) }
  }

  return { isLoading, org, renderTypeAndLastActive }
}

export default useConsentHistory
