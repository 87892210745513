import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'

import useScrollToTop from '../common/hooks/useScrollToTop'

import LocalStorage from '../services/LocalStorage'
import useSessionValidation from '../common/hooks/useSessionValidation'
import SessionModal from '../components/session/SessionModal'
import useHandleLogout from '../common/hooks/useHandleLogout'
import shouldRedirectToReset2FA from './shouldRedirectToReset2FA'
import ROUTES from '../routes/common/constants'

export const ProtectedWalletRoute = ({ path, component: Component, ...rest }: any) => {
  useScrollToTop()
  const { checkIDStoreToken } = useSessionValidation()
  const { logoutMyPei } = useHandleLogout()
  const history = useHistory()

  const sessionWallet = LocalStorage.get('session')

  useEffect(() => {
    checkIDStoreToken()

    if (!sessionWallet) logoutMyPei()

    if (shouldRedirectToReset2FA(path))
        history.push(ROUTES.Change2FARoute)

    // eslint-disable-next-line
  }, [sessionWallet])

  return (
    <>
      <SessionModal />
      <Route
        {...rest}
        render={() => {
          return sessionWallet && <Component />
        }}
      />
    </>
  )
}
