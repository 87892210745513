import parse from 'html-react-parser'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from '../../../../../i18n'

import { AddressChangeForm, BirthdateChangeForm, NameChangeForm, PhoneNumberChangeForm, PreferredNameChangeForm } from '../forms'

import { BoxMessage, ListItem, Notification } from '../../../../../components'

import useIdvVerification from '../../../hooks/useIdvVerification'

import { AuthenticatorI, errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'

import { ReactComponent as Checkmark } from '../../../../../asset/icon-check.svg'
import { ReactComponent as Warning } from '../../../../../asset/icon-warning.svg'
import UpdateSWATModal from './modal/UpdateSWATModal'

import { ManageAccountError } from '../../../../../common/constants'
import { VerifyStatus } from '../../../../../common/constants/enum'
import { EIDVBackBtn } from '../../../../../wallet/types/wallet'
import useSWAT from '../../../hooks/useSWAT'
import useGetProfileParsed from '../hooks/useGetProfileParsed'
import PhoneNumberModal from './modal/PhoneNumberModal'

interface IProfileProps {
  setProfileData: (data: ProfileDataI) => void
  setProfileWithResponseBody: (data: any) => void
  profileData: ProfileDataI
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  isVerified: boolean
  authMethod: AuthenticatorI
}

const ProfileInformation = ({
  setProfileData,
  profileData,
  isVerified,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  setProfileWithResponseBody,
  authMethod,
}: IProfileProps) => {
  const { handleStartIdvVerification } = useIdvVerification()
  const { getFullName, getFullAddress, maskedDob, phoneNumberWithDashes, verifiedIdData } = useGetProfileParsed({ profileData, isVerified })
  const { getSwat } = useSWAT()

  const [infoSaved, setInfoSaved] = useState(false)
  const [activateUpdateNameForm, setActivateUpdateNameForm] = useState(false)
  const [activateUpdateBirthdateForm, setActivateUpdateBirthdateForm] = useState(false)
  const [activateUpdateAddressForm, setActivateUpdateAddressForm] = useState(false)
  const [activateUpdatePreferredNameForm, setActivateUpdatePreferredNameForm] = useState(false)
  const [activateUpdatePhoneNumberForm, setActivateUpdatePhoneNumberForm] = useState(false)
  const [activateUpdateSWATModal, setActivateUpdateSWATModal] = useState(false)

  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false)

  const { preferredName, verifiedStatus, birthdate } = profileData

  const isPhoneNumberEditingDisabled = useMemo(() => authMethod.method === 'SMS', [authMethod])

  const fullName = getFullName()
  const fullAddress = getFullAddress()

  const handleSwatRequest = async () => {
    if (isVerified) {
      const response = await getSwat()
      setProfileWithResponseBody(response)
      setActivateUpdateSWATModal(false)
      setInfoSaved(true)
    }
  }

  const toggleUpdateNameForm = () => {
    setActivateUpdateNameForm(!activateUpdateNameForm)
  }

  const toggleUpdateBirthdateForm = () => {
    setActivateUpdateBirthdateForm(!activateUpdateBirthdateForm)
  }

  const toggleUpdateAddressForm = () => {
    setActivateUpdateAddressForm(!activateUpdateAddressForm)
  }

  const toggleUpdatePhoneNumberForm = () => {
    setActivateUpdatePhoneNumberForm(!activateUpdatePhoneNumberForm)
  }

  const toggleUpdateSWATModal = () => {
    setActivateUpdateSWATModal(!activateUpdateSWATModal)
  }

  const toggleUpdatePreferredNameForm = () => {
    setActivateUpdatePreferredNameForm(!activateUpdatePreferredNameForm)
  }

  const handlePhoneOnClick = () => {
    if (isPhoneNumberEditingDisabled) setIsPhoneNumberModalOpen(true)
    else toggleUpdatePhoneNumberForm()
  }

  useEffect(() => {
    if (infoSaved) setTimeout(() => setInfoSaved(false), 5000)
  }, [infoSaved])

  return (
    <>
      <div className="profile-information-session">
        {infoSaved && <Notification dataTestId="succes-banner" icon={<Checkmark />} text={i18n.t('information-saved')} />}
        {verifiedStatus === VerifyStatus.PENDING && (
          <Notification dataTestId="succes-banner" icon={<Checkmark />} text={i18n.t('identity-verification-notification-in-progress')} />
        )}
        <div className="profile-info-group">
          {errorObj.error && errorObj.error === ManageAccountError.ERROR_LEGAL_NAME && (
            <div className="container-box-message">
              <BoxMessage icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="error-name-form" />
            </div>
          )}
          {activateUpdateNameForm ? (
            <NameChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdateNameForm={toggleUpdateNameForm}
              infoSaved={infoSaved}
              setInfoSaved={setInfoSaved}
              dataTestId="edit-legal-name"
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-personal-information-name')}
              value={fullName}
              dataTestId={'row-legal-name'}
              action={isVerified ? i18n.t('update') : i18n.t('edit')}
              onClick={isVerified ? toggleUpdateSWATModal : toggleUpdateNameForm}
            />
          )}
          {errorObj.error && errorObj.error === ManageAccountError.ERROR_PREFERRED_NAME && (
            <div className="container-box-message">
              <BoxMessage icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="error-preferred-name" />
            </div>
          )}
          {activateUpdatePreferredNameForm ? (
            <PreferredNameChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdatePreferredNameForm={toggleUpdatePreferredNameForm}
              infoSaved={infoSaved}
              setInfoSaved={setInfoSaved}
              dataTestId={'edit-preferred-name'}
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-personal-information-preferred')}
              value={preferredName || i18n.t('not-provided')}
              dataTestId={'row-preferred-name'}
              action={preferredName ? i18n.t('edit') : i18n.t('add')}
              onClick={toggleUpdatePreferredNameForm}
            />
          )}
          {errorObj.error && errorObj.error === ManageAccountError.ERROR_DATE_OF_BIRTH && (
            <div className="container-box-message">
              <BoxMessage icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="error-date-of-birth" />
            </div>
          )}
          {activateUpdateBirthdateForm ? (
            <BirthdateChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdateBirthdateForm={toggleUpdateBirthdateForm}
              infoSaved={infoSaved}
              setInfoSaved={setInfoSaved}
              dataTestId="edit-dob"
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-personal-information-birth')}
              value={isVerified ? maskedDob : birthdate || i18n.t('not-provided')}
              dataTestId={'row-dob'}
              action={isVerified ? i18n.t('update') : birthdate ? i18n.t('edit') : i18n.t('add')}
              onClick={isVerified ? toggleUpdateSWATModal : toggleUpdateBirthdateForm}
            />
          )}
          {errorObj.error && errorObj.error === ManageAccountError.ERROR_ADDRESS && (
            <div className="container-box-message">
              <BoxMessage icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="error-address" />
            </div>
          )}
          {activateUpdateAddressForm ? (
            <AddressChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdateAddressForm={toggleUpdateAddressForm}
              infoSaved={infoSaved}
              setInfoSaved={setInfoSaved}
              dataTestId="edit-address"
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-personal-information-address')}
              value={fullAddress || i18n.t('not-provided')}
              dataTestId={'row-address'}
              action={isVerified ? i18n.t('update') : fullAddress ? i18n.t('edit') : i18n.t('add')}
              onClick={isVerified ? toggleUpdateSWATModal : toggleUpdateAddressForm}
            />
          )}

          {errorObj.error && errorObj.error === ManageAccountError.ERROR_PHONE_NUMBER && (
            <div className="container-box-message">
              <BoxMessage icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="error-phone-number" />
            </div>
          )}
          {activateUpdatePhoneNumberForm ? (
            <PhoneNumberChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdatePhoneNumberForm={toggleUpdatePhoneNumberForm}
              infoSaved={infoSaved}
              setInfoSaved={setInfoSaved}
              dataTestId="edit-phone-number"
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-personal-information-phone')}
              value={phoneNumberWithDashes || i18n.t('not-provided')}
              question={isPhoneNumberEditingDisabled}
              dataTestId={'row-phone'}
              action={phoneNumberWithDashes ? i18n.t('edit') : i18n.t('add')}
              onClick={handlePhoneOnClick}
            />
          )}
          <ListItem
            title={i18n.t('wallet-profile-personal-information-id')}
            value={verifiedIdData}
            dataTestId={'row-verified-doc'}
            action={isVerified ? i18n.t('update') : i18n.t('add')}
            hideAction={profileData?.verifiedStatus === 'pending'}
            last
            onClick={isVerified ? toggleUpdateSWATModal : () => handleStartIdvVerification(isVerified, EIDVBackBtn.PROFILE)}
          />
        </div>
        <UpdateSWATModal
          updateAction={handleSwatRequest}
          isOpen={activateUpdateSWATModal}
          onClose={() => {
            setActivateUpdateSWATModal(false)
          }}
        />
        <PhoneNumberModal
          isOpen={isPhoneNumberModalOpen}
          onClose={() => {
            setIsPhoneNumberModalOpen(false)
          }}
        />
      </div>
    </>
  )
}

export default ProfileInformation
