const ROUTES = {
  LandingPage: '/',
  TermsOfServiceRoute: '/terms-of-service',
  CreatePeiIdRoute: '/create-pei-id',
  CreateInPersonRoute: '/create-in-person',
  OTPRoute: '/otp',
  VerifyPeiIdRoute: '/verify-pei-id',
  SigninRoute: '/sign-in',
  ForgotPasswordRoute: '/forgot-password',
  ForgotPasswordEmailRoute: '/forgot-password-email',
  ResetPasswordRoute: '/reset-password',
  ResetPasswordSuccessRoute: '/reset-password-success',
  OTPSignInRoute: '/otp-sign-in',
  VerifyPeiIdSetupRoute: '/verify-pei-id-setup',
  ReVerifyIdRoute: '/re-verify-id',
  IdentityVerificationRoute: '/verification-information',
  IdentityVerificationErrorRoute: '/identity-verification-error',
  IdentityVerificationLevel1Route: '/identity-verification-level-1',
  IdentityVerificationLevel3Route: '/identity-verification-level-3',
  IdentityVerificationExpiredRoute: '/identity-verification-expired',
  IdentityVerificationExpiredPortalRoute: '/identity-verification-portal',
  IdentityVerificationHealthCardExpiredRoute: '/identity-verification-health-card-expired',
  IdentityVerificationDriverLicenceCardExpiredRoute: '/identity-verification-driver-licence-card-expired',
  IdentityVerificationVoluntaryIdCardExpiredRoute: '/identity-verification-voluntary-id-card-expired',
  IdentityVerificationHealthCardLevel3Route: '/identity-verification-health-card-level-3',
  IdentityVerificationDriverLicenceCardLevel3Route: '/identity-verification-driver-licence-card-level-3',
  IdentityVerificationVoluntaryIdCardLevel3Route: '/identity-verification-voluntary-id-card-level-3',
  AccountSetupHelpRoute: '/account-setup-help',
  AccountReadyRoute: '/account-ready',
  AccountInPersonReadyRoute: '/in-person-account-ready',
  Choose2FARoute: '/choose-2FA',
  AddEmailAuthRoute: '/add-email-auth',
  AddSMSAuthRoute: '/add-sms-auth',
  SMSOTPRoute: '/sms-otp',
  SetupAuthenticatorAppRoute: '/setup-authenticator-app',
  AddAuthenticatorAppRoute: '/add-authenticator-app',
  AddAuthenticatorCodeRoute: '/add-authenticator-code',
  LogoutRoute: '/logout',
  WalletRoute: '/wallet',
  WalletWelcomeRoute: '/wallet/welcome',
  WalletCallback: '/wallet/auth-callback',
  WalletRSCallback: '/wallet/rs-callback',
  WalletConsentCallback: '/wallet/as-authorize',
  WalletConsentRequest: '/wallet/consent-request',
  WalletConsentPage: '/wallet/consent/:transactionID',
  WalletProfile: '/wallet/profile',
  VerifyPeiIdCallbackRoute: '/verify-pei-id-return',
  VerifyPropertyTaxCallbackRoute: '/verify-property-tax-return',
  WalletConsentHistory: '/wallet/consent-history',
  WalletConsentHistoryDetail: '/wallet/detail/:client_id/:client_name/:type',
  WalletIdentityVerification: '/wallet/identity-verification',
  WalletConsentSuccess: '/wallet/consent-success',
  WalletChangeEmailRoute: '/wallet/change-email',
  WalletOTPChangeEmailRoute: '/wallet/change-email-otp',
  Change2FARoute: '/wallet/change-2FA',
  Change2FASmsRoute: '/wallet/change-2FA/sms',
  Change2FASmsOtpRoute: '/wallet/change-2FA/sms/otp',
  Change2FAAppSetupRoute: '/wallet/change-2FA/app',
  Change2FAAppQRRoute: '/wallet/change-2FA/app/qr',
  Change2FAAppCodeRoute: '/wallet/change-2FA/app/code',
  Change2FAEmailRoute: '/wallet/change-2FA/email',
  ErrorGenericRoute: '/error',
}

export default ROUTES
