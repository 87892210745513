import React from 'react'
import { Layout, Title, Text, Button } from '../../../../components'
import i18n from '../../../../i18n'
import parse from 'html-react-parser'
import { useContext } from '../../../../Context'

const GenericErrorPage = () => {
  const {
    state: {
      config: {
        links: { gpei },
      },
    },
  } = useContext();
  const goHome = () => {
    document.location = gpei;
  }
  return (
    <Layout header footer title={i18n.t('error-generic-system-title')} className="generic-error-page-layout">
      <Title dataTestid="title" title={i18n.t('error-generic-system-title')} />
      <Text className="lead-text" dataTestId="description">
        {parse(i18n.t('error-generic-system-description'))}
      </Text>
      <Button onClick={goHome} text={i18n.t('error-generic-system-button')} dataTestId="Button-primary" />
    </Layout>
  )
}

export default GenericErrorPage