import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import { ListItem, Text, Notification } from '../../../../../components'
import i18n from '../../../../../i18n'
import { Features, ProfileDataI } from '../../../../../components/ComponentInterface'

import useGetProfileParsed from '../hooks/useGetProfileParsed'

import useIdvVerification from '../../../hooks/useIdvVerification'
import { EIDVBackBtn } from '../../../../../wallet/types/wallet'
import EditHealthCardModal from './modal/EditHealthCardModal'
import RemovePropertyTaxModal from './modal/RemovePropertyTaxModal'
import { updateWalletProfileAdditionalInformationNotification, useContext } from '../../../../../Context'
import { useTriggeredDeleteHealthCardData } from '../../../../../services'
import { ReactComponent as Checkmark } from '../../../../../asset/icon-check.svg'
import { ReactComponent as Warning } from '../../../../../asset/icon-warning.svg'

interface IAdditionalProps {
  profileData: ProfileDataI
  isVerified: boolean
  setProfileData: (data: any) => void
  getProfile: () => void
}

const AdditionalInformation = ({ profileData, isVerified, setProfileData, getProfile }: IAdditionalProps) => {
  const [activateEditHealthCardModal, setActivateEditHealthCardModal] = useState(false)
  const [showRemovePropertyTaxModal, setShowRemovePropertyTaxModal] = useState(false)
  const { healthCardData, propertyTaxData } = useGetProfileParsed({ profileData, isVerified })
  const { handleStartIdvVerification, getIDVHealthCardLink, getIDVHealthCardUpdate, getIDVPropertyTaxLink } = useIdvVerification()
  const { deleteHealthCardData } = useTriggeredDeleteHealthCardData()

  const { healthCardNumber, properties } = profileData

  const {
    state: {
      userInfo: {
        profile: { additionalInformationNotification },
      },
      config: { feature_flags_enabled },
    },
    dispatch,
  } = useContext()

  const isPropertyTaxEnabled = feature_flags_enabled?.[Features.PropertyTax] || false

  useEffect(() => {
    if (additionalInformationNotification)
      setTimeout(() => {
        dispatch(updateWalletProfileAdditionalInformationNotification(null))
      }, 15000)
  }, [dispatch, additionalInformationNotification])

  const handleIdvHealthCard = async () => {
    if (isVerified) {
      if (healthCardNumber) {
        const response = await getIDVHealthCardUpdate()
        setProfileData(response)
        setActivateEditHealthCardModal(false)
      } else {
        await getIDVHealthCardLink()
      }
    } else {
      handleStartIdvVerification(isVerified, EIDVBackBtn.PROFILE)
    }
  }

  const handleRemoveHealthCard = async () => {
    try {
      const healthCardDeletionResponse = await deleteHealthCardData()
      if (healthCardDeletionResponse.response?.status === 200)
        dispatch(
          updateWalletProfileAdditionalInformationNotification({
            dataTestId: 'success-banner',
            icon: <Checkmark />,
            content: i18n.t('health-card-removed-notification'),
          }),
        )
      else {
        dispatch(
          updateWalletProfileAdditionalInformationNotification({
            dataTestId: 'banner-error',
            icon: <Warning />,
            content: i18n.t('info-update-failed'),
          }),
        )
      }
      await getProfile()
      setActivateEditHealthCardModal(false)
    } catch (e) {
      console.error('Error when removing Health Card: ', e)
    }
  }

  const handleIdvPropertyTax = async () => {
    if (isVerified) {
      if (!!properties && properties.length > 0) {
        setShowRemovePropertyTaxModal(true)
      } else {
        await getIDVPropertyTaxLink()
      }
    } else {
      handleStartIdvVerification(isVerified, EIDVBackBtn.PROFILE)
    }
  }

  return (
    <div className="profile-information-session">
      <Text className="subtitle" dataTestId="h3-additional-info">
        {parse(i18n.t('wallet-profile-additional-information-title'))}
      </Text>
      {!!additionalInformationNotification?.content && (
        <Notification dataTestId={additionalInformationNotification.dataTestId} icon={additionalInformationNotification.icon} text={additionalInformationNotification.content} />
      )}
      <div className="profile-info-group">
        <ListItem
          title={i18n.t('wallet-profile-additional-information-health')}
          value={healthCardData}
          dataTestId={'row-health-card'}
          action={isVerified ? (healthCardNumber ? i18n.t('update') : i18n.t('add')) : ''}
          hideAction={!isVerified}
          onClick={() => (isVerified ? (healthCardNumber ? setActivateEditHealthCardModal(true) : handleIdvHealthCard()) : handleIdvHealthCard())}
        />
        {isPropertyTaxEnabled && (
          <ListItem
            title={i18n.t('wallet-profile-additional-information-property')}
            value={propertyTaxData}
            dataTestId={'row-property-tax'}
            action={isVerified ? (!!properties && properties.length > 0 ? i18n.t('remove') : i18n.t('add')) : ''}
            hideAction={!isVerified}
            onClick={handleIdvPropertyTax}
          />
        )}
      </div>
      <EditHealthCardModal
        updateAction={handleIdvHealthCard}
        removeAction={handleRemoveHealthCard}
        isOpen={activateEditHealthCardModal}
        onClose={() => setActivateEditHealthCardModal(false)}
      />
      <RemovePropertyTaxModal isOpen={showRemovePropertyTaxModal} close={() => setShowRemovePropertyTaxModal(false)} getProfile={getProfile} />
    </div>
  )
}

export default AdditionalInformation
