import React, { useEffect, useState } from 'react'
import { PasscodeFormI } from '../ComponentInterface'
import { PasscodeInput, Button, Spinner, Text } from '..'
import { useTriggeredUpdateOTP } from '../../services'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import OTPSessionExpiredModal from '../session/OTPSessionExpiredModal'
import { handleSuccessErrorResponse } from '../../utils/otpHandleSuccessErrorResponse'

const AuthenticatorCodeForm = ({ successFunc, goBackLink, secondaryButtonDataTestId }: PasscodeFormI) => {
  const { useUpdateOTP: updateOtp, isLoading: isLoadingOtp } = useTriggeredUpdateOTP()
  const [passcode, setPasscode] = useState('')
  const [isValidPasscode, setIsValidPasscode] = useState(false)
  const [message, setMessage] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useEffect(() => {
    if (!isValidPasscode) return
    const verifyPasscodeOTP = async () => {
      const { response, error } = await updateOtp({
        data: { otp: passcode },
      })
      await successErrorResponse(response, error)
    }
    verifyPasscodeOTP()
    // eslint-disable-next-line
  }, [isValidPasscode])

  const successErrorResponse = async (response: any, error: any) => {
    const result = handleSuccessErrorResponse(response, error)

    if (result.showLogoutModal) {
      setShowLogoutModal(true)
    }

    if (result.message !== undefined) {
      setMessage(result.message)
    }

    if (result.isValidPasscode !== undefined) {
      setIsValidPasscode(result.isValidPasscode)
    }

    if (response.isSuccess && !response.data.error) {
      setIsRedirecting(true)
      successFunc && successFunc()
    }
  }

  return (
    <div className="authenticator-code-form">
      {isLoadingOtp || isRedirecting ? (
        <Spinner dark />
      ) : (
        <>
          <PasscodeInput
            onChange={(e: any) => {
              setPasscode(e)
            }}
            onValidate={setIsValidPasscode}
            label={i18n.t('setup-authenticator-app-otp-title')}
            className={message ? 'error' : ''}
            dataTestId="OTP"
            code={message ? passcode : ''}
          />
          {message && (
            <Text className="authenticator-code-form-error-message" dataTestId="error">
              {parse(i18n.t(message))}
            </Text>
          )}
          <Button secondary text={i18n.t('setup-authenticator-app-back')} onClick={goBackLink} dataTestId="Button-secondary" />
        </>
      )}
      {<OTPSessionExpiredModal isModalOpen={showLogoutModal} />}
    </div>
  )
}

export default AuthenticatorCodeForm
