import { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { getConfig, getHash } from '../../services'
import { updateConfig, updatePrevious, updateToken, useContext } from '../../Context'
import i18n from '../../i18n'
import { getLocalStorage } from '../../utils'

const useConfigSetup = () => {
  const [ready, setReady] = useState(false)
  const { dispatch } = useContext()

  const getConfigSetup = async () => {
    const portrait = window.matchMedia('(orientation: portrait)')
    const landscape = window.matchMedia('(orientation: landscape)')
    const configData = await getConfig()

    if (configData?.whitelabel) {
      var headID = document.getElementsByTagName('head')[0]
      var link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      headID.appendChild(link)
      link.href = configData.whitelabel
    }
    dispatch(updateConfig(configData))
    dispatch(updatePrevious(getHash('previous')))
    Modal.setAppElement('#root')
    portrait.addEventListener('change', function (e) {
      if (e.matches) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    })
    landscape.addEventListener('change', function (e) {
      if (e.matches) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    })
    await i18n.init()
    setReady(true)
  }

  useEffect(() => {
    getConfigSetup()
    window.addEventListener('resize', function () {})

    const token = getLocalStorage('idstore-token')
    dispatch(updateToken(token || ''))
    // eslint-disable-next-line
  }, [])

  return { ready }
}

export default useConfigSetup
