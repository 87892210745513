import { useHistory } from 'react-router-dom'
import { useContext, updateError } from '../../../Context'
import { useTriggeredIDVHealthCardOnline, useTriggeredIDVPropertyTaxOnline, useTriggeredIDVUpdateHealthCardOnline, useTriggeredVerification } from '../../../services'
import ROUTES from '../../../routes/common/constants'
import { addSessionStorage } from '../../../utils'
import { useLanguage } from '../../../providers/context/LanguageContext'

const useIdvVerification = () => {
  const { useVerification: startVerification } = useTriggeredVerification()
  const { requestIDVHealthCardLink } = useTriggeredIDVHealthCardOnline()
  const { requestIDVPropertyTaxLink } = useTriggeredIDVPropertyTaxOnline()
  const { requestIDVUpdateHealthCardLink } = useTriggeredIDVUpdateHealthCardOnline()
  const { language } = useLanguage()

  const { dispatch } = useContext()
  const history = useHistory()

  const getIDVHealthCardLink = async () => {
    try {
      const { response } = await requestIDVHealthCardLink()
      if (response.isSuccess) {
        const link = new URL(response?.data?.link);
        link.searchParams.set('lang', language)
        window.location.href = link.toString()
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const getIDVPropertyTaxLink = async () => {
    try {
      const { response } = await requestIDVPropertyTaxLink()

      if (response.isSuccess) {
        const link = new URL(response?.data?.link);
        link.searchParams.set('lang', language)
        window.location.href = link.toString()
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const getIDVHealthCardUpdate = async () => {
    try {
      const { response } = await requestIDVUpdateHealthCardLink()

      if (response.isSuccess) {
        return response?.data
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const handleVerfication = async () => {
    try {
      const { response } = await startVerification()

      if (response.isSuccess) {
        const link = new URL(response?.data?.link);
        link.searchParams.set('lang', language)
        window.location.href = link.toString()
      } else {
        dispatch(updateError(true))
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  const handleStartIdvVerification = (isVerified: boolean, consent: string) => {
    if (isVerified) return

    addSessionStorage('wallet-profile-government', consent)

    history.push(ROUTES.VerifyPeiIdRoute)
  }

  const handleInPerson = () => window.open('https://www.princeedwardisland.ca/en/information/transportation-and-infrastructure/mypei-account-verification-at-access-pei ', '_blank')

  return { handleVerfication, handleStartIdvVerification, getIDVHealthCardLink, getIDVHealthCardUpdate, getIDVPropertyTaxLink, handleInPerson }
}

export default useIdvVerification
