import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../i18n'

import useProfile from './hooks/useProfile'
import { Layout, Spinner, Title, Text, EmailInput, Button } from '../../../../components'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../routes/common/constants'
import { updateAccountEmail, useContext } from '../../../../Context'

export default function WalletChangeEmail() {
  const history = useHistory()
  const {
    dispatch,
    state: {
      userInfo: { change_email },
    },
  } = useContext()

  const { isLoading, profileData, getProfile, postChangeEmail, isLoadingEmail } = useProfile()
  const { email } = profileData

  const [newEmail, setNewEmail] = useState('')
  const [confirmNewEmail, setConfirmNewEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [errorObj, setErrorObj] = useState({
    errorNewEmail: false,
    errorConfirmNewEmail: false,
    showErrorNewEmail: false,
    showConfirmNewEmail: false,
    showEmailsNotMatch: false,
    showRequiredField: false,
    showEmailExists: false,
  })

  useEffect(() => {
    if (change_email?.email && change_email?.confirmEmail) {
      setNewEmail(change_email?.email)
      setConfirmNewEmail(change_email?.confirmEmail)
      setErrorObj((prev) => ({ ...prev, errorNewEmail: true, errorConfirmNewEmail: true }))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { showRequiredField, showEmailsNotMatch, showErrorNewEmail, showConfirmNewEmail } = errorObj

    if (showErrorNewEmail || showConfirmNewEmail) setErrorMessage(i18n.t('invalid-email'))
    if (showRequiredField) setErrorMessage(i18n.t('wallet-change-email-required'))
    if (showEmailsNotMatch) setErrorMessage(i18n.t('wallet-change-email-not-match'))
  }, [errorObj])

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [])

  const cancelUpdateEmail = () => {
    dispatch(
      updateAccountEmail({
        email: '',
        confirmEmail: '',
      }),
    )
    history.push(ROUTES.WalletProfile)
  }

  const updateEmail = async () => {
    const { errorNewEmail, errorConfirmNewEmail } = errorObj

    const hasErrors = !errorNewEmail || !errorConfirmNewEmail || (newEmail && confirmNewEmail && newEmail !== confirmNewEmail)

    setErrorObj((prev) => ({
      ...prev,
      showRequiredField: newEmail === '' || confirmNewEmail === '',
      showErrorNewEmail: !errorNewEmail,
      showConfirmNewEmail: !errorConfirmNewEmail,
      showEmailsNotMatch: !!newEmail && !!confirmNewEmail && newEmail !== confirmNewEmail,
      showEmailExists: false,
    }))

    if (hasErrors) {
      return
    }

    setErrorMessage('')
    dispatch(
      updateAccountEmail({
        email: newEmail,
        confirmEmail: confirmNewEmail,
      }),
    )

    const { isSuccess, isConflictError, isGeneralError } = await postChangeEmail(newEmail)

    if (isGeneralError) {
      if (isConflictError) {
        setErrorObj((prev) => ({
          ...prev,
          showEmailExists: true,
        }))
        setErrorMessage(i18n.t('wallet-change-email-already-exists'))
      } else setErrorMessage(i18n.t('error-server'))

      return
    }

    isSuccess && history.push(ROUTES.WalletOTPChangeEmailRoute)
  }

  return (
    <Layout header footer logout>
      {isLoading ? (
        <div className="wallet-app">
          <div className="profile-loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <div className="wallet-app">
          <div className="wallet-change-email">
            <Title title={i18n.t('wallet-change-email-title')} />
            <div className="change-email-subtitle">
              <Text dataTestId="current-email">{parse(i18n.t('wallet-change-email-current'))}</Text>
            </div>
            <div className="change-email-desc">
              <Text dataTestId="current-email-value">{email}</Text>
            </div>
            {!!errorMessage && (
              <div data-testid={'change-email-error-box'} className="wallet-box-message">
                <div data-testid={'change-email-error-message'}>{parse(errorMessage)}</div>
              </div>
            )}
            <div>
              <div className="input-container">
                <EmailInput
                  onChange={(e) => {
                    setNewEmail(e.target.value)
                  }}
                  value={newEmail}
                  label={i18n.t('wallet-change-email-new')}
                  onValidate={(e) => setErrorObj((prev) => ({ ...prev, errorNewEmail: e }))}
                  dataTestId={'form-email-1'}
                  required
                  isError={errorObj.showErrorNewEmail || errorObj.showEmailsNotMatch || errorObj.showEmailExists}
                />
              </div>
              <div className="input-container-1">
                <EmailInput
                  onChange={(e) => {
                    setConfirmNewEmail(e.target.value)
                  }}
                  value={confirmNewEmail}
                  label={i18n.t('wallet-change-email-validate')}
                  onValidate={(e) => setErrorObj((prev) => ({ ...prev, errorConfirmNewEmail: e }))}
                  dataTestId={'form-email-1'}
                  required
                  isError={errorObj.showConfirmNewEmail || errorObj.showEmailsNotMatch || errorObj.showEmailExists}
                />
              </div>
            </div>
            <div className="btn-group">
              <Button dataTestId="button-cancel" secondary onClick={cancelUpdateEmail} text={i18n.t('cancel')} />
              <Button dataTestId="button-save" isLoading={isLoadingEmail} onClick={updateEmail} text={i18n.t('save')} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}
