import { Ref } from 'react'
import { onChangeType, onClickType, booleanCallback, PasscodeInputType } from './props'

export interface AccordianLeftI {
  text?: string | JSX.Element | JSX.Element[]
  title?: string
  titleClose?: string
}

export interface AccordianRightI {
  children?: any
  title?: string | JSX.Element | JSX.Element[]
}

export type AuthMethod = 'SMS' | 'TOTP' | 'EMAIL'

export interface AuthenticatorI {
  method?: AuthMethod
  description?: string
}

export interface ButtonI {
  className?: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: onClickType
  text?: string
  url?: string
  dataTestId?: string
  secondary?: boolean
}

export interface CardI {
  icon?: string | JSX.Element | JSX.Element[]
  className?: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: onClickType
  url?: string
  title?: string
  description?: string
  reminder?: string
  dataTestId?: string
}

export interface CheckboxI {
  id?: string
  label?: string
  onChange: onChangeType
  onValidate?: booleanCallback
  required?: boolean
  value?: boolean
  dataTestId?: string
}

export interface RadioButtonI {
  checked?: boolean
  id?: string
  image?: string | JSX.Element | JSX.Element[]
  label?: string
  name?: string
  onChange: onChangeType
  value?: string
}

export interface CtaRowI {
  className?: string
  ctaText: string
  index?: number
  onClick?: onClickType
  title?: string
  isExpired?: boolean
}

export interface EditDrawerI {
  afterClickLabel?: string
  children?: any
  label: string
  onClick: onClickType
  openDrawer?: boolean
  title: string
}

export interface EmaiInputI {
  infoClick?: onClickType
  isError?: boolean
  label?: string
  onChange?: onChangeType
  onValidate?: booleanCallback
  optional?: boolean
  required?: boolean
  value?: string
  dataTestId?: string
}

export interface HeaderI {
  logout?: boolean
}

export interface IconButtonI {
  title?: string
  className?: string
  icon?: string | JSX.Element | JSX.Element[]
  onClick?: onClickType
  type?: string
  dataTestId?: string
}

export interface IconListItemI {
  className?: string
  children?: any
  complete?: boolean
  logo?: string | JSX.Element | JSX.Element[]
  dataTestId?: string
}

export interface IconInfoItemI {
  children?: any
  complete?: boolean
  logo?: string | JSX.Element | JSX.Element[]
  dataTestId?: string
}

export interface InfoButtonI {
  onClick?: onClickType
  type?: string
}

export interface LinkButtonI {
  className?: string
  onClick?: onClickType
  text?: string | JSX.Element | JSX.Element[]
  url?: string
  target?: React.HTMLAttributeAnchorTarget
  dataTestId?: string
}

export interface LoginButtonI {
  logo?: JSX.Element | JSX.Element[]
  onClick?: onClickType
  text?: string
}

export interface ModalI {
  canClose?: boolean
  children?: any
  className?: string
  isModalOpen: boolean
  toggleModal?: onClickType
}

export interface NotificationI {
  className?: string
  icon?: string | JSX.Element | JSX.Element[]
  text: string | JSX.Element | JSX.Element[]
  dataTestId?: string
  notificationRef?: Ref<HTMLDivElement>
  info?: boolean
}

export interface NumberInputI {
  infoClick?: onClickType
  label?: string
  maxLength?: number
  onChange?: onChangeType
  onValidate?: booleanCallback
  optional?: boolean
  type?: string
  value?: string
  dataTestId?: string
}

export interface NameDataI {
  first_name: string
  middle_name: string
  last_name: string
}

export interface AddressDataI {
  street1?: string
  street2?: string
  city?: string
  region?: string
  country?: string
  postal_code?: string
}

export interface PhoneInputI {
  infoClick?: onClickType
  label?: string
  maxLength?: number
  onChange?: onChangeType
  onValidate?: booleanCallback
  optional?: boolean
  type?: string
  value?: string
  required?: boolean
  placeholder?: string
  name?: string
  dataTestId?: string
  hasError?: boolean
  errorMessage?: string
}

export interface PasscodeInputI {
  label: string
  onChange?: PasscodeInputType
  onComplete?: PasscodeInputType
  onValidate?: booleanCallback
  className?: string
  dataTestId?: string
  code?: string
}

export interface PasswordInputI {
  description?: string | JSX.Element | JSX.Element[]
  label?: string
  maxLength?: number
  minLength?: number
  onChange?: onChangeType
  onValidate?: booleanCallback
  optional?: boolean
  removeIcon?: boolean
  required?: boolean
  value?: string
  name?: string
  isError?: boolean
  isFocused?: boolean
  handleOnBlurEvent?: () => void
  dataTestId?: string
}

export interface TextInputI {
  value?: string
  infoClick?: onClickType | undefined
  isError?: boolean
  key?: string
  label: string
  labelDescription?: string | JSX.Element | JSX.Element[]
  maxLength?: number
  minLength?: number
  onChange?: (e: any, i: any) => void
  onValidate?: booleanCallback
  optional?: boolean
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  type?: string
  showCtaIcon?: boolean
  hideCta?: boolean
  editClick?: onClickType
  ctaBtnText?: string
  name?: string
  isFocused?: Boolean
  dataTestId?: string
}

export interface NameInputI {
  value?: string
  infoClick?: onClickType | undefined
  isError?: boolean
  key?: string
  label: string
  labelDescription?: string | JSX.Element | JSX.Element[]
  maxLength?: number
  minLength?: number
  onChange?: (e: any, i: any) => void
  onValidate?: booleanCallback
  optional?: boolean
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  type?: string
  showCtaIcon?: boolean
  hideCta?: boolean
  editClick?: onClickType
  ctaBtnText?: string
  name?: string
  isFocused?: Boolean
  dataTestId?: string
}

export interface LayoutI {
  backText?: string
  children?: any
  className?: string
  footer?: boolean
  hasPrevRoute?: string
  header?: boolean
  logout?: boolean
  moreLinks?: boolean
  title?: string
  backBtnCallback?: any
  backclassName?: string
  stepper?: boolean
  stepperLabel?: string
  stepperNumberofSteps?: number
  stepperCurrentStep?: number
}

export interface RecaptchaI {
  asyncScriptOnLoad?: () => void
  onChange: (value: any) => void
  onExpired: () => void
  recaptchaKey: string
  recaptchaRef: any
}

export interface HealthCardFormI {
  readOnlyDob?: boolean
  readOnlyHn?: boolean
  successFunc?: (param?: any) => void
  submitClicked?: booleanCallback
  isReverify?: boolean
  hideDobCta?: boolean
}

export interface DriverLicenseCardFormI {
  authorizeErrorFunc?: () => void
  readOnlyDob?: boolean
  readOnlyDriverLicence?: boolean
  readOnlyFirstName?: boolean
  readOnlyLastName?: boolean
  successFunc?: (param?: any) => void
  submitClicked?: booleanCallback
  isReverify?: boolean
}

export interface VoluntaryCardFormI {
  authorizeErrorFunc?: () => void
  readOnlyDob?: boolean
  readOnlyVoluntary?: boolean
  readOnlyFirstName?: boolean
  readOnlyLastName?: boolean
  successFunc?: (param?: any) => void
  submitClicked?: booleanCallback
  isReverify?: boolean
}

export interface PasscodeFormI {
  apiType?: string
  successFunc?: (e?: any) => void
  goBackLink?: (e?: any) => void
  secondaryButtonDataTestId?: string
  twoFaMethod?: string
}

export interface FooterI {
  moreLinks?: boolean
}

export interface TitleI {
  title: string
  dataTestid?: string
}

export interface TextI {
  children: any
  className?: string
  dataTestId?: string
}

export interface CreateIdEmailPage {
  successFunc: () => void
}

export interface PageI {
  successFunc?: (param?: any) => void
  goBackLink?: any
  startVerificationAction?: any
  otherRoutes?: any
  className?: string
  previousRoute?: string
  errorObj?: any
  setErrorObj?: any
  accesFormErrorObj?: any
}

export interface ResetPasswordFormI {
  successFunc?: () => void
  resetToken?: any
}

export interface SpinnerI {
  dark?: boolean
}

export interface PasswordUpdateFormI {
  profileData?: ProfileDataI
  onInputChange?: (e?: any, value?: any) => void
  setValidateInputs: (e?: any) => void
  errorConfirmPassword?: boolean
  errorPassword?: boolean
  error?: any
  updatePassword: () => void
  isValidPasswordBtn?: boolean
  isLoading?: boolean
  toggleUpdatePasswordForm: () => void
  validInputs?: object
  handleOnBlurEvent?: () => void
}

interface PropertyDataI {
  pid: string
  naid: string
  streetNumber: string
  streetName: string
  communityName: string
  address: string
}
export interface ProfileDataI {
  firstName?: string
  middleName?: string
  lastName?: string
  email?: string
  ialLevel?: string
  credential?: string
  birthdate?: string
  healthCardNumber?: string
  expiryDateHealthCard?: string
  driverLicenseNumber?: string
  expiryDateDriverLicense?: string
  serialNumberDriversLicense?: string
  voluntaryId?: string
  expiryDateVoluntaryId?: string
  serialNumberVoluntaryId?: string
  needsReVerificationFlow?: string
  verifiedStatus?: string
  passwordDate?: string
  preferredName?: string
  address?: AddressDataI
  currentPassword?: string
  newPassword?: string
  confirmPassword?: string
  phoneNumber?: string
  health_card_number?: string
  properties?: PropertyDataI[]
}

export interface InputI {
  confirmPassword?: string
  createPassword?: string
  dln?: string
  dob?: string
  email?: string
  expiryDate?: string
  firstName?: string
  hn?: string
  lastName?: string
  middleName?: string
  newPassword?: string
  password?: string
  serial?: string
  voluntary?: string
  code?: string
  phoneNumber?: string
}

export interface InputValidateI {
  isValidBtn?: boolean
  isValidConfirmPassword?: boolean
  isValidDln?: boolean
  isValidDob?: boolean
  isValidEmail?: boolean
  isValidExpiryDate?: boolean
  isValidFirstName?: boolean
  isValidHn?: boolean
  isValidLastName?: boolean
  isValidMiddleName?: boolean
  isValidPreferredName?: boolean
  isValidNewPassword?: boolean
  isValidPassword?: boolean
  isValidSerial?: boolean
  isValidVoluntary?: boolean
  isValidStreet1?: boolean
  isValidStreet2?: boolean
  isValidCity?: boolean
  isValidRegion?: boolean
  isValidCountry?: boolean
  isValidPostalCode?: boolean
  isValidUpdateNameBtn?: boolean
  isValidUpdatePreferredNameBtn?: boolean
  isValidUpdateBirthdateBtn?: boolean
  isValidUpdateAddressBtn?: boolean
  isValidUpdatePhoneNumberBtn?: boolean
  isValidUpdatePasswordBtn?: boolean
  isValidPasswordBtn?: boolean
  isValidCode?: boolean
  isValidVerifCode?: boolean
  isValidPhoneNumber?: boolean
}

export interface errorI {
  error: string
  errorCurrentPassword?: boolean
  errorConfirmPassword?: boolean
  errorEmail?: boolean
  errorNotification?: boolean
  errorPassword?: boolean
  errorVerifCode?: boolean
  isError?: boolean
  errorPhoneNumber?: boolean
  errorPlaceholderKey?: string
  errorPlaceholderValue?: string
}

export enum Features {
  PropertyTax = 'property_tax',
}

export enum Scopes {
  HealthCard = 'requires_health_card',
  PropertyTax = 'requires_property_tax'
}
