import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import Modal from '../Modal'
import i18n from '../../i18n'
import Button from '../buttons/Button'
import useSessionValidation from '../../common/hooks/useSessionValidation'
import SignInModal from './SignInModal'
import { addLocalStorage, getLocalStorage, removeLocalStorage } from '../../utils'
import { SessionManagement } from '../../common/constants'
import { useContext } from '../../Context'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'

export const RevalidateSessionModal = ({ onClose }: any) => {
  const {
    state: {
      config: {
        session: { warning_popup_time },
      },
    },
  } = useContext()

  const history = useHistory()

  const [openExpiredModal, setOpenExpiredModal] = useState(false)
  const [countDown, setCountDown] = useState(warning_popup_time || 60)
  const { checkIDStoreToken, notWithinBackoffPeriod } = useSessionValidation()

  const handleContinue = () => {
    checkIDStoreToken()
    onClose()
    setCountDown(warning_popup_time)
  }

  const handleLogout = async () => {
    history.push(ROUTES.LogoutRoute)
  }

  useEffect(() => {
    let intervalId: any = undefined

    const expiryDate = getLocalStorage(SessionManagement.TIME_EXPIRATION)
    const expiryMaxDate = getLocalStorage(SessionManagement.TIME_MAX_EXPIRATION)

    if (countDown > 0 && expiryDate !== expiryMaxDate && notWithinBackoffPeriod()) {
      intervalId = setInterval(() => {
        setCountDown((prevSeconds: number) => prevSeconds - 1)
      }, 1000)
    } else {
      setOpenExpiredModal(true)
      intervalId !== null && clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [countDown, notWithinBackoffPeriod])

  return (
    <>
      {!openExpiredModal ? (
        <div>
          <div data-testId={'session-modal-title'} className={'title'}>
            {parse(i18n.t('session-modal-extend-title'))}
          </div>
          <div data-testId={'session-modal-desc'}>{parse(i18n.t('session-modal-extend-desc', { seconds: countDown }))}</div>
          <div className="logout-btn">
            <Button className={'btn-exit'} secondary onClick={handleLogout} text={i18n.t('exit')} dataTestId={'sign-exit'} />
            <Button className={'btn-continue'} onClick={handleContinue} text={i18n.t('continue')} dataTestId={'sign-continue'} />
          </div>
        </div>
      ) : (
        <SignInModal />
      )}
    </>
  )
}

const SessionModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isValidSession, checkIDStoreToken } = useSessionValidation()
  const {
    state: {
      config: {
        session: { extend_period },
      },
    },
  } = useContext()

  const handleUserInteraction = () => {
    addLocalStorage(SessionManagement.USER_INTERACTION, 'true')
  }

  const hasUserInteracted = () => {
    const result = getLocalStorage(SessionManagement.USER_INTERACTION)
    removeLocalStorage(SessionManagement.USER_INTERACTION)

    if (!result) return false

    return true
  }

  useEffect(() => {
    const activitySessionTimer = (extend_period || 30) * 1000

    const intervalId = setInterval(() => {
      const isValid = isValidSession()

      const userInteracted = hasUserInteracted()

      if (userInteracted && isValid) {
        checkIDStoreToken()
        return
      }

      if (!isValid) {
        setIsOpen(true)
      }
    }, activitySessionTimer)

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)

    return () => {
      document.addEventListener('click', handleUserInteraction)
      document.addEventListener('keydown', handleUserInteraction)
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Modal toggleModal={() => setIsOpen(true)} canClose={false} isModalOpen={isOpen} className="session-modal">
      <RevalidateSessionModal onClose={() => setIsOpen(false)} />
    </Modal>
  )
}

export default SessionModal
