import { IWalletClientStorage } from '../wallet'

/**
 * Determine the storage to be used based on the key. (Eg: sessionStorage/localStorage)
 * Currently using localStorage for `session` and `session_expiry_token`, and sessionStorage for anything else
 */
const determineStorage = (key: string) => {
  const sessionKeys = ['cr_token', 'cr_token_history']
  return sessionKeys.includes(key) ? window.sessionStorage : window.localStorage
}

const LocalStorage: IWalletClientStorage = {
  get: (key: string, isObject?: boolean) => {
    const information = determineStorage(key).getItem(key)

    return isObject && information ? JSON.parse(information) : information
  },
  set: (key, value: any, isObject?: boolean) => {
    determineStorage(key).setItem(key, isObject ? JSON.stringify(value) : value)
  },
  delete: (key) => {
    determineStorage(key).removeItem(key)
  },
}

export default LocalStorage
