import React, { FC } from 'react'
import Modal from './Modal'
import Button from './buttons/Button'
import parse from 'html-react-parser'
import i18n from '../i18n'
import { useContext } from "../Context";

type CantAccessAuthModalProps = {
  isOpen: boolean
  onClose: () => void
}

const CantAccessAuthModal: FC<CantAccessAuthModalProps> = ({ isOpen, onClose }) => {
  const { state: { config: { pei_portal_link } }} = useContext();

  return (
    <Modal canClose={false} isModalOpen={isOpen} className="cant-access-Auth-modal">
      <h2 className="title" data-testid={'key-title'}>{`${i18n.t('unable-to-access-authentication-method')}`}</h2>
      <p className="description" data-testid={'key-description'} >
{parse(i18n.t('need-2fa-contact-support', {pei_portal_link }))}     
 </p>
      <Button dataTestId="Button-primary" className="primary-btn" text={i18n.t('okay')} onClick={onClose} />
    </Modal>
  )
}

export default CantAccessAuthModal
