import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  AccountInPersonReadyPage,
  AccountReadyPage,
  AddAuthenticatorAppPage,
  AddAuthenticatorCodePage,
  AddEmailAuthPage,
  AddSMSAuthPage,
  Choose2FAPage,
  CreateAccountInPersonPage,
  CreateIdEmailPage,
  DriverLicenceCardProfileInformationPage,
  ForgotPasswordCheckEmailPage,
  ForgotPasswordPage,
  HealthCardProfileInformationPage,
  IdentityVerificationPage,
  LogoutPage,
  OTPage,
  ResetPasswordPage,
  ResetPasswordSuccessPage,
  SetupAuthenticatorAppPage,
  SignInOTPPage,
  SignInPage,
  SMSOTPPage,
  TermsOfServicePage,
  VerifyIdPage,
  VoluntaryIdCardProfileInformationPage,
} from '../views'

import {
  updateAccountCreation,
  updateAccountEmail,
  updateAuthMethodUpdated,
  updateError,
  updateWalletProfileAdditionalInformationNotification,
  updateTermsAccepted,
  useContext,
} from '../Context'

import { addLocalStorage, getSessionStorage, removeSessionStorage } from '../utils'
//import { getQuerySearch } from '../services'
import { useTriggeredVerification, useTriggeredVerificationReturn } from '../services'

import queryString from 'query-string'
import { Spinner } from '../components'

import { VerificationType } from '../common/constants'
import useSessionValidation from '../common/hooks/useSessionValidation'
import ROUTES from '../routes/common/constants'
import LocalStorage from '../services/LocalStorage'
import Change2FAAppCodePage from '../views/2FA/change-2FA/Change2FAAppCodePage'
import Change2FAAppQRPage from '../views/2FA/change-2FA/Change2FAAppQRPage'
import Change2FAAppSetupPage from '../views/2FA/change-2FA/Change2FAAppSetupPage'
import Change2FAEmailPage from '../views/2FA/change-2FA/Change2FAEmailPage'
import Change2FAPage from '../views/2FA/change-2FA/Change2FAPage'
import Change2FASmsOtpPage from '../views/2FA/change-2FA/Change2FASmsOtpPage'
import Change2FASmsPage from '../views/2FA/change-2FA/Change2FASmsPage'
import useIdp from '../views/Wallet/hooks/useIdp'
import WalletChangeEmailOTPPage from '../views/Wallet/pages/profile/WalletChangeEmailOTPPage'
import usePendingConsentRequestHandler from '../views/Wallet/hooks/usePendingConsentRequestHandler'
import { getServices } from '../wallet/services/ServiceRegistry'
import i18n from '../i18n'
import { ReactComponent as Checkmark } from '../asset/icon-check.svg'
import useRedirection from '../common/hooks/useRedirection'

// Create account flow
const TermsOfServiceRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.CreatePeiIdRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.CreateInPersonRoute
    }

    history.push(tempRoute)
  }

  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }
  return <TermsOfServicePage successFunc={onSuccess} goBackLink={goBackAction} />
}

const CreateIdEmailRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    // REmove history of IN Person Flow if there's any
    removeSessionStorage('createAccountFlow')
    history.push(ROUTES.OTPRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.TermsOfServiceRoute)
  }
  const inPersonAccount = (previousRoute?: string) => history.push(ROUTES.CreateInPersonRoute, { previousRoute })

  return <CreateIdEmailPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ inPersonAccount }} />
}

const AfterCreateIdEmailRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  const goBackAction = () => {
    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.CreatePeiIdRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.CreateInPersonRoute
    }

    history.push(tempRoute)
  }
  return <OTPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ apiType: 'createAccount' }} />
}

const ChangeEmailOTPRouter = () => {
  const history = useHistory()
  const { dispatch } = useContext()
  const onSuccess = () => {
    dispatch(
      updateAccountEmail({
        email: '',
        confirmEmail: '',
      }),
    )
    history.push(`${ROUTES.WalletProfile}?emailUpdated=true`)
  }

  const goBackAction = () => history.push(ROUTES.WalletChangeEmailRoute)

  return <WalletChangeEmailOTPPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ apiType: 'createAccount' }} />
}

const AccountReadyRouter = () => {
  const history = useHistory()
  const { loginIdp } = useIdp()

  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }

  return (
    <AccountReadyPage
      goBackLink={goBackAction}
      otherRoutes={{
        skipClick: () => {
          addLocalStorage('skip_verification', true)
          loginIdp()
        },
        nextClick: () => {
          history.push(ROUTES.VerifyPeiIdRoute)
        },
      }}
    />
  )
}

const VerifyIdRouter = () => {
  let location: any = useLocation()
  const { redirectToPEI } = useRedirection()

  const queryParams: any = queryString.parse(location?.search)

  const history = useHistory()
  const { loginIdp } = useIdp()
  const { useVerification: startVerification } = useTriggeredVerification(queryParams?.return === 'mypei' ? queryParams?.return : '')
  const sessionWallet = LocalStorage.get('session')

  const goBackAction = () => {
    if (queryParams?.return === 'mypei') {
      redirectToPEI()
      return
    }

    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.AccountReadyRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.AccountInPersonReadyRoute
    }

    history.push(tempRoute)
  }

  return (
    <VerifyIdPage
      goBackLink={goBackAction}
      otherRoutes={{
        startVerificationAction: () => {
          return startVerification()
        },

        skipClick: () => {
          if (queryParams?.return === 'mypei') {
            redirectToPEI()
            return
          }

          if (!sessionWallet) loginIdp()
          else history.push(ROUTES.WalletProfile)
        },
      }}
    />
  )
}

// Choose 2FA

const Choose2FARouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.OTPRoute)
  }

  return (
    <Choose2FAPage
      goBackLink={goBackAction}
      otherRoutes={{ totpRoute: ROUTES.SetupAuthenticatorAppRoute, smsRoute: ROUTES.AddSMSAuthRoute, emailRoute: ROUTES.AddEmailAuthRoute }}
    />
  )
}

const AddEmailAuthRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.AccountReadyRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.AccountInPersonReadyRoute
    }

    history.push(tempRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  return <AddEmailAuthPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddSMSAuthRouter = () => {
  const history = useHistory()
  const onSuccess = () => {
    history.push(ROUTES.SMSOTPRoute)
  }
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }
  return <AddSMSAuthPage successFunc={onSuccess} otherRoutes={{ apiType: 'post' }} goBackLink={goBackAction} />
}

const AfterSMSOTPRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.AddSMSAuthRoute)
  }
  const onSuccess = () => {
    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.AccountReadyRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.AccountInPersonReadyRoute
    }

    history.push(tempRoute)
  }

  return <SMSOTPPage goBackLink={goBackAction} successFunc={onSuccess} otherRoutes={{ apiType: 'createAccount' }} />
}

// Log in to MyPEI for the 2nd time (LOA 3)
const SignInRouter = () => {
  const { dispatch } = useContext()
  const { redirectToPEI } = useRedirection()
  const { checkIDStoreToken } = useSessionValidation()
  const history = useHistory()

  const cleanUpAccountCreation = () => {
    dispatch(updateAccountCreation({}))
    dispatch(updateTermsAccepted([]))
  }

  const onSuccess = () => {
    removeSessionStorage('createAccountFlow')
    history.push(ROUTES.OTPSignInRoute)
  }
  const goBackAction = () => redirectToPEI()

  const createAccount = () => {
    cleanUpAccountCreation()
    removeSessionStorage('createAccountFlow')
    history.push(ROUTES.TermsOfServiceRoute)
  }

  const inPersonAccount = () => {
    dispatch(updateTermsAccepted([]))
    history.push(ROUTES.TermsOfServiceRoute)
  }

  useEffect(() => {
    checkIDStoreToken(true)
    // eslint-disable-next-line
  }, [])

  return <SignInPage successFunc={onSuccess} otherRoutes={{ apiType: 'createAccount', createAccount, inPersonAccount }} goBackLink={goBackAction} />
}

const AfterSignInRouter = () => {
  const history = useHistory()
  const { loginIdp } = useIdp()

  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }
  const onSuccess = () => {
    loginIdp()
  }

  return <SignInOTPPage successFunc={onSuccess} goBackLink={goBackAction} otherRoutes={{ apiType: 'post' }} />
}

const ForgotPasswordRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.ForgotPasswordEmailRoute)
  const goBackAction = () => {
    history.push(ROUTES.SigninRoute)
  }

  return <ForgotPasswordPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const AfterForgotPasswordRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.ForgotPasswordRoute)
  }

  return <ForgotPasswordCheckEmailPage goBackLink={goBackAction} />
}

const ResetPasswordRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.ResetPasswordSuccessRoute)
  return <ResetPasswordPage successFunc={onSuccess} />
}

const AfterResetPasswordRouter = () => {
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.SigninRoute)
  return <ResetPasswordSuccessPage successFunc={onSuccess} />
}

// Manage Verifcation

const IdentityVerificationRouter = () => {
  const {
    state: {
      config: { manage_pei_url },
    },
  } = useContext()
  const history = useHistory()
  const onSuccess = () => history.push(ROUTES.VerifyPeiIdSetupRoute)
  const goBackAction = () => (window.location.href = manage_pei_url)

  return (
    <IdentityVerificationPage
      successFunc={onSuccess}
      otherRoutes={{
        voluntaryIdVerification: () => {
          history.push(ROUTES.IdentityVerificationVoluntaryIdCardLevel3Route)
        },
        drivingLicenseVerification: () => {
          history.push(ROUTES.IdentityVerificationDriverLicenceCardLevel3Route)
        },
        healthcardVerification: () => {
          history.push(ROUTES.IdentityVerificationHealthCardLevel3Route)
        },
        reVerification: () => {
          history.push(ROUTES.ReVerifyIdRoute)
        },
      }}
      goBackLink={goBackAction}
    />
  )
}

const IdentityVerificationDriverLicenceCardLevel3Router = () => {
  const history = useHistory()

  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'DRIVER_LICENSE_CARD_VERIFIED' })
  return <DriverLicenceCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const IdentityVerificationVoluntaryIdCardLevel3Router = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'VOLUNTARY_CARD_VERIFIED' })
  return <VoluntaryIdCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const IdentityVerificationHealthCardLevel3Router = () => {
  const history = useHistory()

  const onSuccess = () => history.push(ROUTES.ReVerifyIdRoute, { from: 'manageIdentityVerification', page: 'HEALTH_CARD_VERIFIED' })
  const goBackAction = () => {
    history.push(ROUTES.IdentityVerificationRoute)
  }

  return <HealthCardProfileInformationPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const LogoutRouter = () => {
  return <LogoutPage />
}

// Authenticator App Routers

const SetupAuthenticatorAppRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }

  const onSuccess = () => history.push(ROUTES.AddAuthenticatorAppRoute)

  return <SetupAuthenticatorAppPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddAuthenticatorAppRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.SetupAuthenticatorAppRoute)
  }

  const onSuccess = () => history.push(ROUTES.AddAuthenticatorCodeRoute)

  return <AddAuthenticatorAppPage goBackLink={goBackAction} successFunc={onSuccess} />
}

const AddAuthenticatorCodeRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.AddAuthenticatorAppRoute)
  }

  const onSuccess = () => {
    // Based on the FLow Either create or In person screen
    let tempRoute = ROUTES.AccountReadyRoute

    if (getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.type === VerificationType.IN_PERSON) {
      tempRoute = ROUTES.AccountInPersonReadyRoute
    }

    history.push(tempRoute)
  }

  return <AddAuthenticatorCodePage goBackLink={goBackAction} successFunc={onSuccess} />
}

const VerifyPeiIdCallbackRouter = () => {
  let location: any = useLocation()
  const history = useHistory()

  const queryParams: any = queryString.parse(location?.search)
  const { loginIdp } = useIdp()
  const { redirectToPEI } = useRedirection()

  const { useVerificationReturn: verificationReturn } = useTriggeredVerificationReturn(queryParams?.state, queryParams?.error)
  const { dispatch } = useContext()
  const sessionWallet = LocalStorage.get('session')

  useEffect(() => {
    const returnCall = async () => {
      try {
        if (queryParams?.return === 'mypei' && queryParams?.error === 'cancel') {
          redirectToPEI()
          return
        }

        const { response } = await verificationReturn()

        if (response.isSuccess) {
          if (!sessionWallet) loginIdp()
          else {
            if (queryParams?.return === 'mypei') redirectToPEI()
            else history.push(ROUTES.WalletProfile)
          }
        } else {
          dispatch(updateError(true))
        }
      } catch (e) {
        dispatch(updateError(true))
      }
    }

    returnCall()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="center-page">
      <Spinner dark />
    </div>
  )
}

const VerifyPropertyTaxCallbackRouter = () => {
  let location: any = useLocation()
  const history = useHistory()

  const queryParams: any = queryString.parse(location?.search)
  const { loginIdp } = useIdp()

  const { useVerificationReturn: verificationReturn } = useTriggeredVerificationReturn(queryParams?.state, queryParams?.error)
  const { dispatch } = useContext()
  const sessionWallet = LocalStorage.get('session')

  useEffect(() => {
    const returnCall = async () => {
      try {
        const { response } = await verificationReturn()

        if (response.isSuccess) {
          if (!sessionWallet) loginIdp()
          else {
            if (!queryParams?.error)
              dispatch(
                updateWalletProfileAdditionalInformationNotification({
                  dataTestId: 'success-banner',
                  icon: <Checkmark />,
                  content: i18n.t('property-info-saved'),
                }),
              )
            history.push(ROUTES.WalletProfile)
          }
        } else {
          dispatch(updateError(true))
        }
      } catch (e) {
        dispatch(updateError(true))
      }
    }
    returnCall()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="center-page">
      <Spinner dark />
    </div>
  )
}

const CreateInPersonRouter = () => {
  const history = useHistory()
  let location: any = useLocation()

  const onSuccess = () => {
    history.push(ROUTES.OTPRoute)
  }

  const goBackAction = () => {
    let tempRoute: string = ROUTES.TermsOfServiceRoute
    if (location?.state?.previousRoute) {
      tempRoute = location?.state?.previousRoute
    }

    history.push(tempRoute)
  }

  return <CreateAccountInPersonPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const AccountInPersonReadyRouter = () => {
  const { loginIdp } = useIdp()
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Choose2FARoute)
  }

  return (
    <AccountInPersonReadyPage
      goBackLink={goBackAction}
      otherRoutes={{
        skipClick: () => {
          loginIdp()
        },
      }}
    />
  )
}

const Change2FARouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.WalletProfile)
  }
  return (
    <Change2FAPage
      goBackLink={goBackAction}
      otherRoutes={{ totpRoute: ROUTES.Change2FAAppSetupRoute, smsRoute: ROUTES.Change2FASmsRoute, emailRoute: ROUTES.Change2FAEmailRoute }}
    />
  )
}

const Change2FAAppCodeRouter = () => {
  const history = useHistory()
  const { storage } = getServices()
  const { dispatch } = useContext()
  const { handlePendingConsentRequest } = usePendingConsentRequestHandler()
  const goBackAction = () => {
    history.push(ROUTES.Change2FAAppQRRoute)
  }
  const onSuccess = () => {
    const defaultHandler = () => {
      dispatch(updateAuthMethodUpdated(true))
      history.push(ROUTES.WalletProfile)
    }
    storage.delete('reset_status')
    handlePendingConsentRequest(defaultHandler)
  }
  return <Change2FAAppCodePage goBackLink={goBackAction} successFunc={onSuccess} />
}

const Change2FAAppQRRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Change2FAAppSetupRoute)
  }
  const onSuccess = () => {
    history.push(ROUTES.Change2FAAppCodeRoute)
  }
  return <Change2FAAppQRPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const Change2FAAppSetupRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Change2FARoute)
  }
  const onSuccess = async () => {
    history.push(ROUTES.Change2FAAppQRRoute)
  }
  return <Change2FAAppSetupPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const Change2FAEmailRouter = () => {
  const history = useHistory()
  const { storage } = getServices()
  const { dispatch } = useContext()
  const { handlePendingConsentRequest } = usePendingConsentRequestHandler()

  const goBackAction = () => {
    history.push(ROUTES.Change2FARoute)
  }
  const onSuccess = async () => {
    const defaultHandler = () => {
      dispatch(updateAuthMethodUpdated(true))
      history.push(ROUTES.WalletProfile)
    }
    storage.delete('reset_status')
    handlePendingConsentRequest(defaultHandler)
  }
  return <Change2FAEmailPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const Change2FASmsRouter = () => {
  const history = useHistory()
  const goBackAction = () => {
    history.push(ROUTES.Change2FARoute)
  }
  const onSuccess = async () => {
    history.push(ROUTES.Change2FASmsOtpRoute)
  }
  return <Change2FASmsPage successFunc={onSuccess} goBackLink={goBackAction} />
}

const Change2FASmsOtpRouter = () => {
  const history = useHistory()
  const { storage } = getServices()
  const { dispatch } = useContext()
  const { handlePendingConsentRequest } = usePendingConsentRequestHandler()
  const goBackAction = () => {
    history.push(ROUTES.Change2FARoute)
  }
  const onSuccess = async () => {
    const defaultHandler = () => {
      dispatch(updateAuthMethodUpdated(true))
      history.push(ROUTES.WalletProfile)
    }
    storage.delete('reset_status')
    handlePendingConsentRequest(defaultHandler)
  }
  return <Change2FASmsOtpPage successFunc={onSuccess} goBackLink={goBackAction} />
}

export {
  AccountInPersonReadyRouter,
  AccountReadyRouter,
  AddAuthenticatorAppRouter,
  AddAuthenticatorCodeRouter,
  AddEmailAuthRouter,
  AddSMSAuthRouter,
  AfterCreateIdEmailRouter,
  AfterForgotPasswordRouter,
  AfterResetPasswordRouter,
  AfterSignInRouter,
  AfterSMSOTPRouter,
  Change2FAAppCodeRouter,
  Change2FAAppQRRouter,
  Change2FAAppSetupRouter,
  Change2FAEmailRouter,
  Change2FARouter,
  Change2FASmsOtpRouter,
  Change2FASmsRouter,
  Choose2FARouter,
  CreateIdEmailRouter,
  CreateInPersonRouter,
  ForgotPasswordRouter,
  IdentityVerificationDriverLicenceCardLevel3Router,
  IdentityVerificationHealthCardLevel3Router,
  IdentityVerificationRouter,
  IdentityVerificationVoluntaryIdCardLevel3Router,
  LogoutRouter,
  ResetPasswordRouter,
  SetupAuthenticatorAppRouter,
  SignInRouter,
  TermsOfServiceRouter,
  VerifyIdRouter,
  VerifyPeiIdCallbackRouter,
  VerifyPropertyTaxCallbackRouter,
  ChangeEmailOTPRouter,
}
