import parse from 'html-react-parser'
import React, { useEffect } from 'react'
import { Layout, Text, Title } from '../../../components'
import AuthSelector from '../../../components/authSelector/AuthSelector'
import { PageI } from '../../../components/ComponentInterface'
import i18n from '../../../i18n'
import useAccountInformation from '../../Wallet/pages/profile/hooks/useAccountInformation'
import { getServices } from '../../../wallet/services/ServiceRegistry'
import { ResetStatus } from '../../../common/constants/enum'

enum AuthMethodMessage {
  'TOTP' = 'change-auth-description-totp',
  'SMS' = 'change-auth-description-sms',
  'EMAIL' = 'change-auth-description-email',
}

const Change2FAPage = ({ goBackLink, otherRoutes: { totpRoute, smsRoute, emailRoute } }: PageI) => {
  const { authMethod, getAuthMethod } = useAccountInformation()
  const { storage } = getServices();
  useEffect(() => {
    getAuthMethod()
    // eslint-disable-next-line
  }, [])

  if (storage.get('reset_status') === ResetStatus.RESET_2FA)
    return (
      <Layout header footer title={i18n.t('change-auth-title')} className={'change-2FA'}>
        <Title title={i18n.t('add-auth-title')} />
        <Text className="lead-text">{i18n.t('add-auth-description')}</Text>
        <AuthSelector totpRoute={totpRoute} smsRoute={smsRoute} emailRoute={emailRoute} usingEmail={true} />
      </Layout>
    )
  else 
    return (
      <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('cancel')} title={i18n.t('change-auth-title')} className={'change-2FA'}>
        <Title title={i18n.t('change-auth-title')} />
        <Text className="lead-text">{parse(i18n.t(!!authMethod?.method ? AuthMethodMessage[authMethod.method] : ''))}</Text>
        <Text className="lead-sub-text">{i18n.t('change-auth-description-2')}</Text>
        <AuthSelector totpRoute={totpRoute} smsRoute={smsRoute} emailRoute={emailRoute} usingEmail={authMethod.method === "EMAIL"} />
      </Layout>
    )
}

export default Change2FAPage
