import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'
import useConsent from '../../../hooks/useConsent'
import { getServices } from '../../../../../wallet/services/ServiceRegistry'
import { Button } from '../../../../../components'
import useIdvVerification from '../../../hooks/useIdvVerification'

export enum IDVType {
  HEALTH_CARD = 'HEALTH_CARD',
  PROPERTY = 'PROPERTY',
}
interface Props {
  type: IDVType
}

const Verified = ({ type }: Props) => {
  const [scopeName, setScopeName] = useState({ client: '' })
  const [isLoading, setIsLoading] = useState(false)
  const { storage } = getServices()

  const { getIDVHealthCardLink, getIDVPropertyTaxLink } = useIdvVerification()

  const transactionID = storage.get('cr_token')
  const { generateClientName } = useConsent(transactionID!)

  useEffect(() => {
    if (!scopeName?.client) {
      setScopeName({ client: generateClientName() })
    }
  }, [scopeName, generateClientName])

  const handleIDVRedirection = async () => {
    setIsLoading(true)
    if (type === IDVType.HEALTH_CARD) await getIDVHealthCardLink()
    if (type === IDVType.PROPERTY) await getIDVPropertyTaxLink()
  }

  return (
    <div className="wallet-identity-verified">
      <div className="wallet-identity-verified-subtitle" data-testid={'identity-verified-subtitle'}>
        {parse(
          i18n.t(type === IDVType.HEALTH_CARD ? 'wallet-identity-verification-verified-health-desc' : 'wallet-identity-verification-verified-property-desc', {
            service: scopeName.client,
          }),
        )}
      </div>

      <Button
        className={'wallet-identity-verified-btn'}
        isLoading={isLoading}
        onClick={handleIDVRedirection}
        text={i18n.t(type === IDVType.HEALTH_CARD ? 'wallet-identity-verification-verified-health-btn' : 'wallet-identity-verification-verified-property-btn')}
        dataTestId={'Button-primary'}
      />
    </div>
  )
}

export default Verified
