import { useContext } from '../../Context'
import { useLanguage } from '../../providers/context/LanguageContext'

export default function useRedirection() {
  const { language } = useLanguage()

  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()

  const redirectToPEI = () => {
    window.location.href = `${pei_portal_link}?lang=${language}`
  }

  return { redirectToPEI }
}
