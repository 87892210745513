import { useHistory } from 'react-router-dom'
import { useWallet } from '../../../context/wallet'
import ROUTES from '../../../routes/common/constants'
import { getServices } from '../../../wallet/services/ServiceRegistry'
import LocalStorage from '../../../services/LocalStorage'
import { useLanguage } from '../../../providers/context/LanguageContext'

/**
 * Handles what happens with the pending consent request.
 * @returns the function to handle the pending consent request
 */
const usePendingConsentRequestHandler = () => {
  const { storage, config } = getServices()
  const { language } = useLanguage();
  const {
    Consent: { isDeferred },
  } = useWallet()
  const history = useHistory()
  const handlePendingConsentRequest = (defaultHandler: () => void) => {
    const autoConsent = storage.get('auto_consent')
    const transaction_id = storage.get('cr_token')
    if (autoConsent) {
      storage.delete('cr_token')
      storage.delete('auto_consent')
      window.location.href = config.ws_url + '/tx/' + transaction_id + '/continue?lang=' + language
      return
    }

    if (isDeferred && transaction_id) {
      history.push(`${ROUTES.WalletConsentCallback}?token=${transaction_id}&client=${LocalStorage.get('client')!}`)
    } else {
      defaultHandler()
    }
  }
  return { handlePendingConsentRequest }
}

export default usePendingConsentRequestHandler
