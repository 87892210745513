import { ResetStatus } from '../common/constants/enum';
import ROUTES from '../routes/common/constants'
import { getServices } from '../wallet/services/ServiceRegistry'

const shouldRedirectToReset2FA = (currentRoute:string) => {

    const { storage } = getServices();

    if (storage.get('reset_status') !== ResetStatus.RESET_2FA)
        return false;

    const routes_2fa = [ROUTES.Change2FARoute, ROUTES.Change2FASmsRoute, ROUTES.Change2FASmsOtpRoute, ROUTES.Change2FAAppSetupRoute, ROUTES.Change2FAAppQRRoute, ROUTES.Change2FAEmailRoute]

    return !routes_2fa.includes(currentRoute);
}

export default shouldRedirectToReset2FA
