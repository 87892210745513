import { useHistory } from 'react-router-dom'
import { interpolateRoute } from '../../../common/utils/interpolateRoute'
import ROUTES from '../../../routes/common/constants'
import { getServices } from '../../../wallet/services/ServiceRegistry'
import { useTriggeredProfile } from '../../../services'
import { useState } from 'react'
import useConsent from './useConsent'
import { VerifyStatus } from '../../../common/constants/enum'

export interface StatusProps {
  verifiedStatus: string
  healthCardNumber: string
  properties?: any[]
}

const useVerifyUserStatus = () => {
  const [status, setStatus] = useState<StatusProps>({ verifiedStatus: '', healthCardNumber: '', properties: [] })

  const history = useHistory()
  const { storage } = getServices()
  const transaction_id = storage.get('cr_token')

  const { verifyAllPermissionsShared, containScopeToShowVerificationFlow, checkRequestedScopeData, autoAgreeToConsent } = useConsent(transaction_id!)

  const { useProfile: profile } = useTriggeredProfile()

  const checkVerification = async () => {
    try {
      const showVerificationFlow = await containScopeToShowVerificationFlow()
      const hasAlreadySharedConsent = await verifyAllPermissionsShared()

      if (!showVerificationFlow) {
        if (!hasAlreadySharedConsent) {
          transaction_id && history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: transaction_id })}`, { replace: true })
        } else {
          await autoAgreeToConsent()
        }
        return true
      } else {
        const {
          response: { isSuccess, data },
        } = await profile()

        if (isSuccess) {
          const { verified_status, health_card_number = '', properties = [] } = data
          const isVerified = verified_status === VerifyStatus.VERIFIED

          setStatus({ verifiedStatus: verified_status, healthCardNumber: health_card_number, properties })

          const containInfos = await checkRequestedScopeData({ healthCardNumber: health_card_number, properties })

          if (isVerified && containInfos && transaction_id) {
            if (hasAlreadySharedConsent) {
              await autoAgreeToConsent()
            } else {
              transaction_id && history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: transaction_id })}`, { replace: true })
            }
            return true
          }

          return false
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    checkVerification,
    status,
  }
}

export default useVerifyUserStatus
