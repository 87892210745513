import apiFetcher from '../apiFetch'
import { useContext } from '../../Context'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../routes/common/constants'
import { addLocalStorage, getLocalStorage } from '../../utils'
import { SessionManagement } from '../constants'
import useHandleLogout from './useHandleLogout'
import { getConfig } from '../../services'

/**
 * This hooks is responsible to verify if the idp token is valid
 */
const useSessionValidation = () => {
  const {
    state: {
      token,
      config: {
        session: { warning_popup_time, back_off_time },
      },
    },
  } = useContext()

  const { logoutMyPei, redirectLandingPage } = useHandleLogout()

  const history = useHistory()

  const timeRemaining = () => {
    const dateString = getLocalStorage(SessionManagement.TIME_EXPIRATION)

    if (!dateString) return 0

    const expiry = new Date(parseInt(dateString) * 1000)
    const now = new Date()

    const utcExpiry = new Date(
      expiry.getUTCFullYear(),
      expiry.getUTCMonth(),
      expiry.getUTCDate(),
      expiry.getUTCHours(),
      expiry.getUTCMinutes(),
      expiry.getUTCSeconds(),
      expiry.getUTCMilliseconds(),
    )

    const utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

    if (utcExpiry.getTime() >= utcDateNow.getTime()) return (utcExpiry.getTime() - utcDateNow.getTime()) / 1000

    return 0
  }

  const notWithinBackoffPeriod = () => {
    const maxTime = getLocalStorage(SessionManagement.TIME_MAX_EXPIRATION)

    if (!maxTime) return false

    const dataMaxExpiration = new Date(parseInt(maxTime) * 1000)
    const now = new Date()

    const utcExpiry = new Date(
      dataMaxExpiration.getUTCFullYear(),
      dataMaxExpiration.getUTCMonth(),
      dataMaxExpiration.getUTCDate(),
      dataMaxExpiration.getUTCHours(),
      dataMaxExpiration.getUTCMinutes(),
      dataMaxExpiration.getUTCSeconds(),
      dataMaxExpiration.getUTCMilliseconds(),
    )

    const utcDateNow = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

    const result = utcExpiry.getTime() - utcDateNow.getTime() > back_off_time * 1000

    return result
  }

  const isValidTime = (dateString: number) => {
    if (!dateString) return false

    const expiry = new Date(dateString * 1000 - warning_popup_time * 1000)
    const now = new Date()

    const utcExpiry = new Date(
      expiry.getUTCFullYear(),
      expiry.getUTCMonth(),
      expiry.getUTCDate(),
      expiry.getUTCHours(),
      expiry.getUTCMinutes(),
      expiry.getUTCSeconds(),
      expiry.getUTCMilliseconds(),
    )
    const nowUTCDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

    if (isNaN(expiry.getTime())) {
      return false
    }

    return utcExpiry.getTime() >= nowUTCDate.getTime()
  }

  const checkIDStoreToken = async (isLoginPage?: boolean) => {
    const { base_url } = await getConfig()

    if (!token) {
      if (!isLoginPage) {
        await redirectLandingPage()
      }
      return
    }

    try {
      const header = {
        Authorization: `Bearer ${token}`,
      }

      const response = await apiFetcher({
        method: 'POST',
        url: `${base_url}/session/ping`,
        headers: header,
      })
      const { status, data } = response

      if (status === 200) {
        const { expiry, max_expiry } = data
        addLocalStorage(SessionManagement.TIME_EXPIRATION, expiry)
        addLocalStorage(SessionManagement.TIME_MAX_EXPIRATION, max_expiry)
        const timeBeforeExpire = Math.floor(timeRemaining())
        addLocalStorage(SessionManagement.TIME_BEFORE_EXPIRE, timeBeforeExpire)
        isLoginPage && history.push(ROUTES.WalletProfile)
      } else {
        await logoutMyPei()
      }
    } catch (e) {
      await logoutMyPei()
    }
  }

  const isValidSession = () => {
    const expiryDate = getLocalStorage(SessionManagement.TIME_EXPIRATION)

    if (expiryDate) return isValidTime(parseInt(expiryDate))
  }

  return { checkIDStoreToken, token, isValidSession, notWithinBackoffPeriod }
}

export default useSessionValidation
