import React, { useState } from 'react'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { Layout, SignInForm, AccessPeiForm, Title, Notification, Button } from '../../components'
import { PageI, errorI } from '../../components/ComponentInterface'

const SignInPage = ({ successFunc, otherRoutes }: PageI) => {
  const [errorSignInObj, setErrorSignInObj] = useState<errorI>({ error: '' })
  const [errorPeiVerifObj, setErrorPeiVerifObj] = useState<errorI>({ error: '' })

  return (
    <>
      <Layout header footer className="sign-in" title={i18n.t('sign-in-title')}>
        <Title title={i18n.t('sign-in-title')} />
        <div data-testid="pilot-banner">
          <Notification text={parse(i18n.t('sign-in-pilot-message'))} className="error" dataTestId="banner-error" info />
        </div>
        {errorSignInObj.errorNotification && (
          <Notification
            text={parse(i18n.t(errorSignInObj.error, { [`${errorSignInObj.errorPlaceholderKey}`]: errorSignInObj.errorPlaceholderValue }))}
            className="error"
            dataTestId="banner-error"
          />
        )}

        <SignInForm successFunc={successFunc} errorObj={errorSignInObj} setErrorObj={setErrorSignInObj} />
        <div className="post-sign-in-form">
          <div className="sign-up-redirect">
            <h2>{parse(i18n.t('sign-in-no-account'))}</h2>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('sign-in-create-account-content') }} />
            <Button onClick={otherRoutes?.createAccount} text={i18n.t('create-account')} dataTestId="Button-secondary" />
            <h3>{parse(i18n.t('sign-in-create-account-sub-head'))}</h3>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('sign-in-create-account-sub-body') }} />
          </div>
          <AccessPeiForm successFunc={otherRoutes?.inPersonAccount} errorObj={errorPeiVerifObj} setErrorObj={setErrorPeiVerifObj} />
        </div>
      </Layout>
    </>
  )
}

export default SignInPage
