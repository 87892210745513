import React, { useEffect, useState } from 'react'
import i18n from '../../../../i18n'

import Unverified from './components/Unverified'
import Verified, { IDVType } from './components/Verified'
import Pending from './components/Pending'
import useVerifyUserStatus, { StatusProps } from '../../hooks/useVerifyUserStatus'

import { If, Layout, Spinner, Title } from '../../../../components'
import { VerifyStatus } from '../../../../common/constants/enum'
import { useContext } from '../../../../Context'
import { Features, Scopes } from '../../../../components/ComponentInterface'
import LocalStorage from '../../../../services/LocalStorage'

interface OptionStatusProps {
  status: StatusProps
}

const OptionByStatus = ({ status: { verifiedStatus, healthCardNumber, properties } }: OptionStatusProps) => {
  const {
    state: {
      config: { feature_flags_enabled },
    },
  } = useContext()
  const transactionHistory = LocalStorage.get('cr_token_history', true)
  const transactionId = LocalStorage.get('cr_token')

  const isPropertyTaxEnabled = feature_flags_enabled?.[Features.PropertyTax] || false

  if (verifiedStatus === VerifyStatus.VERIFIED) {
    if (!healthCardNumber && (!transactionHistory || !transactionId || transactionHistory[transactionId].requested_resources.some(resource=>resource.scopes_requested.some(scopeObj=>scopeObj.scope === Scopes.HealthCard)))) return <Verified type={IDVType.HEALTH_CARD} />
    if (isPropertyTaxEnabled && !properties?.length) return <Verified type={IDVType.PROPERTY} />
  }

  if (verifiedStatus === VerifyStatus.PENDING) return <Pending />

  return <Unverified />
}

const WalletIdentityVerificationPage = () => {
  const { checkVerification, status } = useVerifyUserStatus()
  const [isLoading, setIsLoading] = useState(true)

  const {
    state: {
      config: { pei_portal_link },
    },
  } = useContext()

  useEffect(() => {
    checkVerification().then((response) => setIsLoading(!!response))
    // eslint-disable-next-line
  }, [])

  const handleGoBack = () => {
    if (!!pei_portal_link && !!window) {
      window.location.assign(pei_portal_link)
    } else {
      console.error('Error when trying to go to PEI portal link')
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="wallet-layout">
          <div className="loading">
            <Spinner dark />
          </div>
        </div>
      ) : (
        <Layout header footer logout backText={!isLoading ? i18n.t('back') : ''} backBtnCallback={handleGoBack}>
          <If condition={!isLoading}>
            <Title title={i18n.t('wallet-identity-verification-title')} dataTestid={'identity-verification-title'} />
            <OptionByStatus status={status} />
          </If>
        </Layout>
      )}
    </>
  )
}

export default WalletIdentityVerificationPage
