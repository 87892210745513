import React, { useEffect } from 'react'
import { Route, Redirect, useHistory } from 'react-router-dom'

import ROUTES from '../routes/common/constants'
import useSessionValidation from '../common/hooks/useSessionValidation'
import SessionModal from '../components/session/SessionModal'
import useHandleLogout from '../common/hooks/useHandleLogout'
import shouldRedirectToReset2FA from './shouldRedirectToReset2FA'

const scrollTop = () => window.scrollTo(0, 0)

const ProtectedRoute = ({ path, component: Component, ...rest }: any) => {
  const { token, checkIDStoreToken } = useSessionValidation()
  const { logoutMyPei } = useHandleLogout()
  const history = useHistory()
  
  useEffect(() => {
    checkIDStoreToken()
    if (!token) logoutMyPei()
    // eslint-disable-next-line

    if (shouldRedirectToReset2FA(path))
      history.push(ROUTES.Change2FARoute)


  }, [checkIDStoreToken, token, history, path, logoutMyPei])

  

  return (
    <>
      <SessionModal />
      <Route
        {...rest}
        render={() => {
          scrollTop()
          return token && <Component />
        }}
      />
    </>
  )
}

export const DefaultRoute = ({ ...rest }) => {
  return <Route {...rest} render={() => <Redirect to={{ pathname: ROUTES.LandingPage }} />} />
}

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={() => {
        scrollTop()
        return <Component />
      }}
    />
  )
}

export default ProtectedRoute
