import moment from 'moment'
import i18n from '../i18n'

export const addSessionStorage = (key: string, data: any) => {
  sessionStorage.setItem(key, data)
}

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key)
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

export const addLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, data)
}

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key)
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const nameRegex = (value: string) => {
  // eslint-disable-next-line
  const regex = /^[A-Za-zÀ-ÿ\s'-\.]+$/
  return value === '' || regex.test(value)
}

export const postalCodeRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([0-9]|$)([aA-zZ]|$)([0-9]|$)([aA-zZ]|$)([0-9]|$)/)
  return regex.test(value.split(' ').join(''))
}

export const versionCodeRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$){2}/)
  return regex.test(value.split(' ').join(''))
}

export const healthCardNumberRegex = (value: string) => {
  const regex = new RegExp(/^[0-9]*$/)
  return regex.test(transformText(value))
}

export const documentIdentifierRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([aA-zZ]|$)([0-9]|$){7}/)
  return regex.test(transformText(value))
}

export const numberRegex = (value: string) => {
  const regex = new RegExp(/^[0-9]*$/)
  return regex.test(transformText(value))
}

export const ontarioPhotoCardRegex = (value: string) => {
  const regex = new RegExp(/^([0-9]|$)([0-9]|$)([0-9]|$)([aA-zZ]|$)([aA-zZ]|$)([0-9]|$){7}/)
  return regex.test(transformText(value))
}

export const driverLicenceRegex = (value: string) => {
  const regex = new RegExp(/^([aA-zZ]|$)([0-9]|$){14}/)
  return regex.test(transformText(value))
}

export const transformText = (text: string) => text.split('-').join('')

export const capitalizeText = (text: string) => {
  return text
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const maskMonthDate = (text: string) => {
  return text.replace(/\/\d{2}/g, '/**')
}

export const formatPostalCode = (text: string) => {
  if (text.length === 6) {
    return text.substring(0, 3) + ' ' + text.substring(3, 6)
  } else if (text.length === 7) {
    return text.substring(0, 3) + ' ' + text.substring(4, 7)
  } else {
    return text
  }
}

/**
 * Masks the main part of a domain, leaving only the first and last characters visible for domains
 * longer than three characters, or only the last character for domains with three or fewer characters.
 * The domain extension (e.g., ".com") is kept intact.
 *
 * @param {string} domain - The domain string to be masked, typically from an email address.
 *                          It can contain multiple parts separated by dots, e.g., "example.com".
 * @returns {string} The masked domain, with the main part partially hidden and the extension visible.
 *                   For example, "example.com" becomes "e*****e.com", and "abc.co" becomes "a\*c.co".
 */
const maskDomain = (domain: string) => {
  const [mainDomain, ...extensionParts] = domain.split('.')
  const extension = extensionParts.join('.')
  const maskedMainDomain =
    mainDomain.length === 1
      ? '*'
      : mainDomain.length <= 3
      ? '*'.repeat(mainDomain.length - 1) + mainDomain.slice(-1)
      : mainDomain[0] + '*'.repeat(mainDomain.length - 2) + mainDomain.slice(-1)
  return `${maskedMainDomain}.${extension}`
}

/**
 * Masks an email address by partially hiding the username and domain parts according to specified rules.
 * - The username part shows only the first and last characters if it is longer than two characters.
 * - The domain part is masked using the `maskDomain` function, preserving the domain extension.
 *
 * @param {string} email - The email address to be masked, in the format "username@domain.com".
 * @returns {string} The masked email address, with the user and domain parts partially hidden.
 *                   For example, "elon@x.com" becomes "e**n@\*.com".
 */
export const maskEmail = (email: string) => {
  const [user, domain] = email.split('@')
  const maskedUser = user.length <= 2 ? user : user[0] + '*'.repeat(user.length - 2) + user.slice(-1)
  const maskedDomain = maskDomain(domain)
  return maskedUser + '@' + maskedDomain
}

export const maskHealthCard = (text: string) => {
  return new Array(text.length - 2).fill('*').join('') + text.substr(text.length - 2, text.length)
}

export const maskId = (text: string) => {
  return text.slice(0, -2).replace(/./g, '*') + text.slice(-2)
}

// eslint-disable-next-line
export const removeDashSpaceSpecialChar = (text: string) => {
  // eslint-disable-next-line
  return (
    text
      .replace(/-/g, '')
      .replace(/ /g, '')
      .replace(/\//g, '')
      // eslint-disable-next-line
      .replace(/[`~!@#$%^&*()_|+\-=?;:'',.<>\{\}\[\]\\\/]/gi, '')
  )
}

export const addDashesIndex = (text: string, index?: Array<number>) =>
  text
    .replace(/-/g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index?.includes(i) ? (curr += '-') : curr), '')

export const addSpaceIndex = (text: string, index: Array<number>) =>
  text
    .replace(/ /g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index.includes(i) ? (curr += ' ') : curr), '')

export const addSlashIndex = (text: string, index: Array<number>) =>
  text
    .replace(/\//g, '')
    .split('')
    .reduce((acc, curr, i) => acc + (index.includes(i) ? (curr += '/') : curr), '')

export const setDashesIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addDashesIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === '-' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setSpaceIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addSpaceIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === ' ' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setSlashIndex = (text: string, index: Array<number>, maxLength: number) => {
  let transformedText = addSlashIndex(removeDashSpaceSpecialChar(text), index)
  return (transformedText.slice(-1) === '/' ? transformedText.slice(0, -1) : transformedText).slice(0, maxLength)
}

export const setFullDateInput = (e: any, updateDateFunc: () => void, isExpiryDate?: boolean) => {
  const removeDashesText = removeDashSpaceSpecialChar(e)
  const year = Number(removeDashesText.slice(0, 4))
  const month = Number(removeDashesText.slice(4, 6))
  const date = Number(removeDashesText.slice(6, 8))
  const minDate = '1900-01-01'
  const todayDate = isExpiryDate ? moment(new Date()).add(10, 'y').format(i18n.t('dob-placeholder')) : new Date().toISOString().slice(0, 10)
  const monthNum1 = Number(removeDashesText.slice(4, 5))

  // validate input is equal or greater than today date input year
  const yearCompare = (yearDigit: number) => {
    const minDateNum = Number(minDate.slice(0, yearDigit))
    const todayDateNum = Number(todayDate.slice(0, yearDigit))
    const isBetweenMinTodayDate = Number(removeDashesText) >= minDateNum && Number(removeDashesText) <= todayDateNum
    return isBetweenMinTodayDate || removeDashesText === ''
  }

  // validate first 3 digits of year (e.g. validate 2, 20, 202)
  if (removeDashesText.length <= 3 && yearCompare(removeDashesText.length)) updateDateFunc()

  switch (removeDashesText.length) {
    // validate 4 digit year is between minYear and current year (e.g. validate 2022
    case 4:
      moment(removeDashesText).isBetween(minDate.slice(0, 4), todayDate.slice(0, 4), undefined, '[]') && updateDateFunc()
      break
    case 5:
      // validate first digit of month (e.g. validate 2022-0)
      ;(monthNum1 === 0 || monthNum1 === 1) && updateDateFunc()
      break
    case 6:
      // validate 2 digits of month (e.g. validate 2022-02)
      moment(setDashesIndex(e, [3, 5], 10)).isBetween(minDate.slice(0, 7), todayDate.slice(0, 7), undefined, '[]') && updateDateFunc()
      break
    case 7:
      // validate first digits of date (e.g. validate 2022-02-1)
      // if user types 2022-02-3 this is valid in moment.js because it's seen as 2022-02-03. so changing it to be 2022-03-30 and it will be invalid
      // if user types 2022-02-2 this is valid because it's seen as 2022-02-20.
      // if user types 2022-02-1 this is valid because it's seen as 2022-02-10.
      // if user types 2022-02-0 this is valid
      removeDashesText.slice(6, 7).length > 0 &&
        (moment(`${year}-${('0' + month).slice(-2)}-${Number(date)}0`).isBetween(minDate, todayDate, undefined, '[]') || date === 0) &&
        updateDateFunc()
      break
    case 8:
      // validate 2 digits of date (e.g. validate 2022-02-02)
      moment(setDashesIndex(e, [3, 5], 10)).isBetween(minDate, todayDate, undefined, '[]') && updateDateFunc()
      break
    default:
      break
  }
}

export const setExpiryDateInput = (e: any, updateDateFunc: () => void) => {
  const removeDashesText = removeDashSpaceSpecialChar(e)
  // const minDate = new Date().toISOString().slice(0, 10);
  // const todayDate = new Date().toISOString().slice(0, 10);
  const minDate = '1900-01'
  const todayDate = moment(new Date()).add(10, 'y').format(i18n.t('dob-placeholder'))
  const monthNum1 = Number(removeDashesText.slice(4, 5))

  // validate input is equal or greater than today date input year
  const yearCompare = (yearDigit: number) => {
    const minDateNum = Number(minDate.slice(0, yearDigit))
    const todayDateNum = Number(todayDate.slice(0, yearDigit))
    const isBetweenMinTodayDate = Number(removeDashesText) >= minDateNum && Number(removeDashesText) <= todayDateNum
    return isBetweenMinTodayDate || removeDashesText === ''
  }

  // validate first 3 digits of year (e.g. validate 2, 20, 202)
  if (removeDashesText.length <= 3 && yearCompare(removeDashesText.length)) updateDateFunc()
  switch (removeDashesText.length) {
    // validate 4 digit year is between minYear and current year (e.g. validate 2022
    case 4:
      // remove the dash add the /
      moment(removeDashesText).isBetween(minDate.slice(0, 4), todayDate.slice(0, 4), undefined, '[]') && updateDateFunc()
      break
    case 5:
      // validate first digit of month (e.g. validate 2022-0)
      ;(monthNum1 === 0 || monthNum1 === 1) && updateDateFunc()
      break
    case 6:
      // validate 2 digits of month (e.g. validate 2022-02)
      moment(setDashesIndex(e, [3, 5], 7)).isBetween(minDate.slice(0, 7), todayDate.slice(0, 7), undefined, '[]') && updateDateFunc()
      break
    default:
      break
  }
}

export const convertUTCtoLocalTimezone = (utcDate: string) => {
  const formattedDate = moment(utcDate).local().format('YYYY-MM-DD HH:mm:ss')

  return new Date(formattedDate)
}
