import { useHistory } from 'react-router-dom'
import { updateAccountCreation, updateProfileState, updateTermsAccepted, updateToken, useContext } from '../../Context'
import ROUTES from '../../routes/common/constants'
import { useTriggeredLogout } from '../../services'
import { useLanguage } from '../../providers/context/LanguageContext'

export default function useHandleLogout() {
  const { useLogout: logoutRequest } = useTriggeredLogout()
  const { language } = useLanguage();
  const history = useHistory()

  const {
    state: {
      config: { pei_portal_link, phrsaml_logout_url },
    },
    dispatch,
  } = useContext()

  const cleanUpUserSessionInfo = () => {
    // Save the language selection before clearing localStorage
    const languageSelection = localStorage.getItem('i18nLanguage');
  
    dispatch(updateToken(''));
    dispatch(updateProfileState({}));
    dispatch(updateAccountCreation({}));
    dispatch(updateTermsAccepted([]));
    window.history.replaceState('state', '');
    
    // Clear storage
    window.localStorage.clear();
    window.sessionStorage.clear();
  
    // Restore the language selection
    if (languageSelection) {
      localStorage.setItem('i18nLanguage', languageSelection);
    }
  };
  

  const handleLogout = async () => {
    await logoutRequest()
    cleanUpUserSessionInfo()
  }

  const logoutMyPei = async () => {
    await handleLogout()
    const logoutUrl = `${pei_portal_link}?logout=true&lang=${language}`
    window.location.href = logoutUrl
  }

  const logoutPhrsaml = async () => {
    await handleLogout()
    const logoutUrl = `${phrsaml_logout_url}`
    window.location.href = logoutUrl
  }

  const redirectLandingPage = async () => {
    await handleLogout()
    history.push(ROUTES.LandingPage)
  }

  return { logoutMyPei, logoutPhrsaml, redirectLandingPage }
}
