import { useMemo } from 'react'
import { ProfileDataI } from '../../../../../components/ComponentInterface'
import { capitalizeText, maskId, maskHealthCard, maskMonthDate, formatPostalCode, setDashesIndex, setSlashIndex } from '../../../../../utils'
import i18n from '../../../../../i18n'
import { VerifyStatus } from '../../../../../common/constants/enum'

interface Props {
  profileData: ProfileDataI
  isVerified?: boolean
}

const useGetProfileParsed = ({ profileData, isVerified }: Props) => {
  const {
    driverLicenseNumber,
    voluntaryId,
    expiryDateVoluntaryId,
    expiryDateDriverLicense,
    firstName,
    middleName,
    lastName,
    address,
    expiryDateHealthCard,
    healthCardNumber,
    properties,
    birthdate,
    phoneNumber,
    verifiedStatus,
  } = profileData

  const getFullName = () => capitalizeText([firstName, middleName, lastName].filter(Boolean).join(' '))

  const getFullAddress = () => {
    const finalAddress =
      address &&
      (address?.street1 ? `${address?.street1}, ` : '') +
        (address?.street2 ? `${address?.street2}, ` : '') +
        (address?.city ? `${address?.city}, ` : '') +
        (address?.region ? `${address?.region}, ` : 'PE, ') +
        (address?.postal_code ? formatPostalCode(`${address?.postal_code}`) : '')
    if (finalAddress === 'PE, ') {
      return ''
    }
    return finalAddress
  }

  const expiryDate = useMemo(() => {
    if (expiryDateHealthCard) return setSlashIndex(expiryDateHealthCard, [3, 5], 10)

    return ''
  }, [expiryDateHealthCard])

  const healthCardData = useMemo(() => {
    return isVerified ? (healthCardNumber ? `${maskHealthCard(healthCardNumber)}, Exp ${expiryDate}` : i18n.t('not-provided')) : i18n.t('verify-to-add')
  }, [isVerified, expiryDate, healthCardNumber])

  const propertyTaxData = useMemo(
    () => (isVerified ? (!!properties && properties.length > 0 ? properties[0].address : i18n.t('not-provided')) : i18n.t('verify-to-add')),
    [isVerified, properties],
  )
  const maskedDob = birthdate ? maskMonthDate(setSlashIndex(birthdate, [3, 5], 10)) : ''
  const phoneNumberWithDashes = phoneNumber ? setDashesIndex(phoneNumber, [2, 5], 12) : ''

  const license = driverLicenseNumber ? maskId(driverLicenseNumber) : voluntaryId ? maskId(voluntaryId) : ''
  const licenseExpiry = expiryDateDriverLicense ? setSlashIndex(expiryDateDriverLicense, [3, 5], 10) : expiryDateVoluntaryId ? setSlashIndex(expiryDateVoluntaryId, [3, 5], 10) : ''

  const verifiedIdData =
    verifiedStatus === VerifyStatus.PENDING
      ? i18n.t('identity-verification-in-progress')
      : isVerified
      ? driverLicenseNumber
        ? `Driver's License, ${license}, Exp ${licenseExpiry}`
        : voluntaryId
        ? `Voluntary ID, ${license}, Exp ${licenseExpiry}`
        : i18n.t('not-provided')
      : i18n.t('not-provided')

  return {
    getFullName,
    getFullAddress,
    expiryDate,
    healthCardData,
    expiryDateHealthCard,
    propertyTaxData,
    maskedDob,
    phoneNumberWithDashes,
    license,
    licenseExpiry,
    verifiedIdData,
  }
}

export default useGetProfileParsed
